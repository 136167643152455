import React, { useCallback, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Grid from '@material-ui/core/Grid';
import Switch from '@material-ui/core/Switch';
import isEmpty from 'lodash/isEmpty';
import moment from 'moment-timezone';
import { DelimitedNumericArrayParam, StringParam, withDefault } from 'use-query-params';

import CustomTextInput from '@parkly/shared/components/atoms/CustomTextInput';
import MultiItemSelectors from '@parkly/shared/components/molecules/MultiItemSelectors';
import { toNativeTimeFormat } from '@parkly/shared/helpers';

import { useGatewayEvents } from 'api/query/gatewayEvents';
import DateTimeRangePicker from 'components/molecules/DateTimeRangePicker';
import PersonalAccountSelect from 'components/molecules/PersonalAccountSelect';
import FiltersContainer from 'components/templates/containers/FiltersContainer';
import {
  DEFAULT_GATEWAY_EVENTS_TYPES_FILTERS,
  FAIL_GATEWAY_EVENTS_STATUSES,
  GATEWAY_EVENTS_STATUSES,
  GATEWAY_EVENTS_TYPES, LS_KEYS,
  MAP_GATEWAY_EVENTS_STATUS_COLOR,
  MAP_GATEWAY_EVENTS_STATUS_NAME,
  MAP_GATEWAY_EVENTS_TYPES_NAME,
  MODULES_KEYS,
  RIGHT_KEYS,
} from 'config/constants';
import {
  useCheckFacilityRights, useCommonRights, useDebounce, useHasFacilityModule, useLocalStorageState,
} from 'helpers/hooks';
import {
  createStringEnumDelimitedArrayParam, PageParam, withParams, withQueryParams,
} from 'helpers/queryParams';

import GatewayEventsContent from './GatewayEventsContent';
import { useStyles } from './styles';

const STATUS_ITEMS = [
  {
    id: `${GATEWAY_EVENTS_STATUSES.inProcess}`,
    title: MAP_GATEWAY_EVENTS_STATUS_NAME[GATEWAY_EVENTS_STATUSES.inProcess],
    color: MAP_GATEWAY_EVENTS_STATUS_COLOR[GATEWAY_EVENTS_STATUSES.inProcess],
  },
  {
    id: `${GATEWAY_EVENTS_STATUSES.success}`,
    title: MAP_GATEWAY_EVENTS_STATUS_NAME[GATEWAY_EVENTS_STATUSES.success],
    color: MAP_GATEWAY_EVENTS_STATUS_COLOR[GATEWAY_EVENTS_STATUSES.success],
  },
  {
    id: '0',
    title: 'Ошибка',
    color: 'red',
  },
];

const FAIL_STATUS_ITEMS = FAIL_GATEWAY_EVENTS_STATUSES.map((id) => ({
  id: `${id}`,
  title: MAP_GATEWAY_EVENTS_STATUS_NAME[id],
}));

const TYPES_ITEMS = [
  { id: [GATEWAY_EVENTS_TYPES.manual, GATEWAY_EVENTS_TYPES.manualNoCustomer].join(','), title: MAP_GATEWAY_EVENTS_TYPES_NAME[GATEWAY_EVENTS_TYPES.manual] },
  { id: [GATEWAY_EVENTS_TYPES.callAppeared, GATEWAY_EVENTS_TYPES.callDtmf, GATEWAY_EVENTS_TYPES.callOffline, GATEWAY_EVENTS_TYPES.laurentPhoneCall].join(','), title: MAP_GATEWAY_EVENTS_TYPES_NAME[GATEWAY_EVENTS_TYPES.callAppeared] },
  { id: `${GATEWAY_EVENTS_TYPES.camera}`, title: MAP_GATEWAY_EVENTS_TYPES_NAME[GATEWAY_EVENTS_TYPES.camera] },
  { id: `${GATEWAY_EVENTS_TYPES.parkly}`, title: MAP_GATEWAY_EVENTS_TYPES_NAME[GATEWAY_EVENTS_TYPES.parkly] },
  { id: `${GATEWAY_EVENTS_TYPES.wiegand}`, title: MAP_GATEWAY_EVENTS_TYPES_NAME[GATEWAY_EVENTS_TYPES.wiegand] },
  { id: `${GATEWAY_EVENTS_TYPES.initDraft}`, title: MAP_GATEWAY_EVENTS_TYPES_NAME[GATEWAY_EVENTS_TYPES.initDraft] },
  { id: `${GATEWAY_EVENTS_TYPES.attachToken}`, title: MAP_GATEWAY_EVENTS_TYPES_NAME[GATEWAY_EVENTS_TYPES.attachToken] },
];

function getInitParams(params) {
  return {
    status: [],
    failStatus: [],
    type: [],
    gateIds: [],
    personalAccount: [],
    dateTo: null,
    dateFrom: null,
    search: '',
    sorting: {},
    page: 1,
    ...params,
  };
}

function getNextSortStatus(status) {
  if (!status) {
    return 'sort';
  }

  if (status === 'sort') {
    return 'sortReverse';
  }

  return null;
}

function prepareParam({
  params,
}) {
  const {
    sorting = {},
    status,
    failStatus,
    type,
    gateIds,
    personalAccount,
    dateTo,
    dateFrom,
    search,
    page,
    perPage,
  } = params || {};

  const sortRules = Object.keys(sorting).filter((currentId) => !!sorting[currentId]);
  const sortParam = sortRules.length > 0
    ? {
      sortBy: sortRules.join(','),
      sortDirection: sortRules.map((id) => {
        const rule = sorting[id];
        if (rule === 'sortReverse') {
          return 'desc';
        }

        return 'asc';
      }).join(','),
    }
    : {};

  const searchValue = (search || '').trim();

  const reqParam = {
    page,
    perPage,
    gateIds,
    personalAccount,
    dateTo,
    dateFrom,
    status,
    failStatus,
    type,
    search: searchValue,
    ...sortParam,
  };

  return reqParam;
}

function GatewayEvents({
  showControls = false,
  showPagination = false,
  facilityId,
  refetchInterval = 1000 * 10, // 1 mins
  gatesData = [],
  // from HOC
  params,
  setParams,
}) {
  const styles = useStyles();
  const { t } = useTranslation();
  const history = useHistory();

  const hasControllersRight = useCheckFacilityRights(RIGHT_KEYS.automationUpdate, facilityId);
  const hasPersonalAccountModule = useHasFacilityModule(facilityId, MODULES_KEYS.personalAccounts);
  const hasPersonalAccountChargeRight = useCommonRights(RIGHT_KEYS.personalAccountsCharge);
  const [showControllers, setShowControllers] = useLocalStorageState(LS_KEYS.gatewayLogsShowControllers, false);

  const variables = useDebounce(params, 300);

  const { data: gatewayEvents, isLoading } = useGatewayEvents({
    variables: { ...variables, facilityId },
    refetchInterval,
    refetchIntervalInBackground: true,
  });

  const gateItems = useMemo(() => {
    const result = (gatesData || []).map(({ id, name }) => ({
      id,
      title: name,
    }));

    result.push({
      id: 0,
      title: 'Не определено',
    });

    return result;
  }, [gatesData]);

  const {
    gatewayEventsData,
    gatewayEventsMeta,
  } = gatewayEvents || {};

  const {
    status,
    failStatus,
    gateIds,
    dateTo,
    dateFrom,
    sorting,
    type,
    search,
    personalAccount,
  } = params;

  const handleParamChange = (paramName, newValue) => {
    if (params[paramName] === newValue) {
      return;
    }

    setParams((prevState) => ({
      ...prevState,
      page: 1,
      [paramName]: newValue,
    }));
  };
  function handleChanges(event) {
    const propName = event.target.name;
    const { value } = event.target;
    handleParamChange(propName, value);
  }
  function onPageChange(_, pageNumber) {
    handleParamChange('page', pageNumber);
  }
  function sortingSettingsChange({ headerNameId }) {
    const nextSortStatus = getNextSortStatus(sorting[headerNameId]);
    if (nextSortStatus === null) {
      handleParamChange('sorting', {});
      return;
    }

    handleParamChange('sorting', {
      [headerNameId]: getNextSortStatus(sorting[headerNameId]),
    });
  }

  const handleDateRangeChange = useCallback((from, to) => {
    setParams((prevState) => ({
      ...prevState,
      page: 1,
      dateFrom: toNativeTimeFormat(from),
      dateTo: toNativeTimeFormat(to),
    }));
  }, [setParams]);

  function handleStatusChange(event) {
    handleChanges(event);
    const { value } = event.target;

    if (value.includes('0') && !status.includes('0')) {
      handleParamChange('failStatus', FAIL_GATEWAY_EVENTS_STATUSES);
    }

    if (!value.includes('0')) {
      handleParamChange('failStatus', []);
    }
  }

  useEffect(() => {
    if (isEmpty(gatesData)) {
      return;
    }

    handleParamChange('gateIds', gatesData.map(({ id }) => (id)));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [gatesData]);

  return (
    <Grid container spacing={2}>
      {showControls && (
        <FiltersContainer>
          <Grid item xs={12}>
            <Grid container spacing={1}>
              <Grid item xs={12} sm={6} md={4} lg={3}>
                <CustomTextInput
                  style={{ margin: 0 }}
                  label="Поиск"
                  name="search"
                  onChange={handleChanges}
                  value={search}
                />
              </Grid>
              <Grid item xs={12} sm={6} md={4} lg={3}>
                <DateTimeRangePicker
                  label="Период"
                  inputFormat="DD.MM HH:mm"
                  clearable
                  rangesType="filters"
                  pastSearchFriendly
                  // smartMode
                  initStart={dateFrom ? moment(dateFrom) : null}
                  initEnd={dateTo ? moment(dateTo) : null}
                  onChange={({ start, end }) => {
                    handleDateRangeChange(start, end);
                  }}
                />
              </Grid>
              <Grid item xs={12} sm={6} md={4} lg={3}>
                <MultiItemSelectors
                  classNameForm={styles.statusSelectForm}
                  className={styles.statusSelect}
                  name="gateIds"
                  currentValue={gateIds}
                  items={gateItems}
                  unselectText="Проезд не выбран"
                  onChange={handleChanges}
                />
              </Grid>
              <Grid item xs={12} sm={6} md={4} lg={3}>
                <MultiItemSelectors
                  classNameForm={styles.statusSelectForm}
                  className={styles.statusSelect}
                  name="type"
                  currentValue={type}
                  items={TYPES_ITEMS}
                  unselectText="Тип не выбран"
                  onChange={handleChanges}
                  limitSelected={2}
                />
              </Grid>
              <Grid item xs={12} sm={6} md={4} lg={3}>
                <MultiItemSelectors
                  classNameForm={styles.statusSelectForm}
                  className={styles.statusSelect}
                  name="status"
                  currentValue={status}
                  items={STATUS_ITEMS}
                  unselectText="Статус не выбран"
                  onChange={handleStatusChange}
                />
              </Grid>
              {status.includes('0') && (
                <Grid item xs={12} sm={6} md={4} lg={3}>
                  <MultiItemSelectors
                    classNameForm={styles.statusSelectForm}
                    className={styles.statusSelect}
                    name="failStatus"
                    currentValue={failStatus}
                    items={FAIL_STATUS_ITEMS}
                    unselectText="Тип ошибки"
                    limitSelected={1}
                    onChange={handleChanges}
                    fastSelectOne
                  />
                </Grid>
              )}
              {hasPersonalAccountModule && hasPersonalAccountChargeRight && (
                <Grid item xs={12} sm={6} md={4} lg={3}>
                  <PersonalAccountSelect
                    name="personalAccount"
                    currentValue={personalAccount}
                    onChange={handleChanges}
                    unselectText="Лицевой счет не выбран"
                  />
                </Grid>
              )}
              {hasControllersRight && (
                <Grid item xs={12} sm={6} md={4} lg={3}>
                  <FormControlLabel
                    control={(
                      <Switch
                        checked={showControllers}
                        onChange={(e) => { setShowControllers(e.target.checked); }}
                        color="primary"
                      />
                    )}
                    label="Показывать технические данные"
                  />
                </Grid>
              )}
            </Grid>
          </Grid>
        </FiltersContainer>
      )}
      <Grid item xs={12}>
        <GatewayEventsContent
          facilityId={facilityId}
          isLoading={isLoading}
          gatewayEventsData={gatewayEventsData}
          gatewayEventsMeta={gatewayEventsMeta}
          showPagination={showPagination}
          onPageChange={onPageChange}
          sorting={sorting}
          sortingSettingsChange={sortingSettingsChange}
          hideControllers={!showControllers}
        />
      </Grid>
    </Grid>
  );
}

export const GatewayEventsWithParams = withParams({
  initParams: getInitParams(),
  prepareParam,
})(GatewayEvents);

const StatusArrayEnumParam = createStringEnumDelimitedArrayParam([
  GATEWAY_EVENTS_STATUSES.inProcess,
  GATEWAY_EVENTS_STATUSES.success,
  0,
]);

const FailStatusArrayEnumParam = createStringEnumDelimitedArrayParam(FAIL_GATEWAY_EVENTS_STATUSES);

const TypeArrayEnumParam = createStringEnumDelimitedArrayParam([
  [GATEWAY_EVENTS_TYPES.manual, GATEWAY_EVENTS_TYPES.manualNoCustomer].join(','),
  [GATEWAY_EVENTS_TYPES.callAppeared, GATEWAY_EVENTS_TYPES.callDtmf, GATEWAY_EVENTS_TYPES.callOffline, GATEWAY_EVENTS_TYPES.laurentPhoneCall].join(','),
  GATEWAY_EVENTS_TYPES.camera,
  GATEWAY_EVENTS_TYPES.initDraft,
  GATEWAY_EVENTS_TYPES.attachToken,
  GATEWAY_EVENTS_TYPES.parkly,
  GATEWAY_EVENTS_TYPES.wiegand,
]);

const GatewayEventsWithQueryParams = withQueryParams({
  queryParams: {
    dateTo: withDefault(StringParam, ''),
    dateFrom: withDefault(StringParam, ''),
    gateIds: withDefault(DelimitedNumericArrayParam, []),
    personalAccount: withDefault(DelimitedNumericArrayParam, []),
    status: withDefault(StatusArrayEnumParam, []),
    failStatus: withDefault(FailStatusArrayEnumParam, []),
    type: withDefault(TypeArrayEnumParam, DEFAULT_GATEWAY_EVENTS_TYPES_FILTERS),

    search: withDefault(StringParam, ''),
    page: PageParam,
  },
  prepareParam,
})(GatewayEvents);

export default GatewayEventsWithQueryParams;
