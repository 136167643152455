import React, { useCallback, useState } from 'react';
import { connect } from 'react-redux';
import Grid from '@material-ui/core/Grid';
import Pagination from '@material-ui/lab/Pagination';

import CircularIndeterminate from '@parkly/shared/components/atoms/CircularIndeterminate';
import { useModal } from '@parkly/shared/helpers';

import PaymentsTable from 'components/templates/PaymentsTable';
import { RIGHT_KEYS } from 'config/constants';
import { useCheckFacilityRights } from 'helpers/hooks';

import CancelPaymentModalContent from '../../CancelPaymentModalContent';
import PaymentInfoModalContent from '../../PaymentInfoModalContent';

import { useStyles } from './styles';

function PaymentsContent({
  facilityId,
  isLoading = false,
  paymentsData = [],
  paymentsMeta,
  showPagination = false,
  onPageChange = () => {},
  sorting,
  sortingSettingsChange,
  hideCustomer = false,
  onNeedUpdate = () => {},
}) {
  const styles = useStyles();

  const {
    currentPage = 1,
    lastPage = 1,
  } = paymentsMeta || {};

  const hasPaymentsCancelRight = useCheckFacilityRights(RIGHT_KEYS.paymentsCancel, facilityId);

  const [cancelModalNode, openCancelModal, hideCancelModal] = useModal({
    content: <CancelPaymentModalContent
      onComplete={() => {
        onNeedUpdate();
        hideCancelModal();
      }}
      onClose={() => {
        hideCancelModal();
      }}
    />,
  });

  const [infoModalNode, openInfoModal, hideInfoModal] = useModal({
    content: <PaymentInfoModalContent
      onClose={() => {
        hideInfoModal();
      }}
    />,
  });

  const handleOnCancel = useCallback(({ id, facilityId: payableFacilityId }) => {
    if (!hasPaymentsCancelRight) {
      return;
    }
    openCancelModal({ paymentId: id, facilityId: payableFacilityId });
  }, [hasPaymentsCancelRight, openCancelModal]);

  const handleOnPaymentClick = useCallback(({ id, facilityId: payableFacilityId }) => {
    openInfoModal({ paymentId: id, facilityId: payableFacilityId });
  }, [openInfoModal]);

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        {isLoading && (
          <CircularIndeterminate style={{ minHeight: 600 }} />
        )}
        {!isLoading && (
          <PaymentsTable
            facilityId={facilityId}
            hideCustomer={hideCustomer}
            hideBtn={!hasPaymentsCancelRight}
            paymentsData={paymentsData}
            sorting={sorting}
            sortingSettingsChange={sortingSettingsChange}
            onCancel={handleOnCancel}
            onPaymentClick={handleOnPaymentClick}
          />
        )}
        {!isLoading && lastPage > 1 && showPagination && (
          <div className={styles.paginationContainer}>
            <Pagination
              className={styles.pagination}
              name="page"
              page={currentPage}
              onChange={onPageChange}
              count={lastPage}
              color="primary"
            />
          </div>
        )}
      </Grid>
      {cancelModalNode}
      {infoModalNode}
    </Grid>
  );
}

function mapStateToProps(state) {
  return {};
}

const ConnectedSessionsContent = connect(
  mapStateToProps,
  {},
)(PaymentsContent);

export default ConnectedSessionsContent;
