// import PropTypes from 'prop-types';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { Card, Grid } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import BlockIcon from '@material-ui/icons/Block';
import EditIcon from '@material-ui/icons/Edit';
import StopIcon from '@material-ui/icons/Stop';
import moment from 'moment-timezone';

import CustomBtn from '@parkly/shared/components/atoms/CustomBtn';
import CustomLink from '@parkly/shared/components/atoms/CustomLink';
import CircleIcon from '@parkly/shared/components/atoms/icons/CircleIcon';
import GridTitleBase from '@parkly/shared/components/molecules/GridTitleBase';
import { getHumanizeDurationShort } from '@parkly/shared/helpers';

import { useImageViewer } from 'components/atoms/ImageViewer';
import SessionAccessToken from 'components/molecules/SessionAccessToken';
import {
  MODULES_KEYS, PATH_PAGES, RIGHT_KEYS, SESSION_STATUSES,
} from 'config/constants';
import { useCheckFacilityRights, useHasFacilityModule } from 'helpers/hooks';

import { useStyles } from './styles';

/* help function */

const getStatusContentByStatus = (status) => {
  switch (status) {
    case SESSION_STATUSES.active:
      return (
        <>
          <CircleIcon color="#43D37D" />
          Активна
        </>
      );
    case SESSION_STATUSES.finished:
      return (
        <>
          <CircleIcon color="grey" />
          Завершена
        </>
      );
    case SESSION_STATUSES.stopped:
      return (
        <>
          <CircleIcon color="red" />
          Остановлена
        </>
      );
    case SESSION_STATUSES.canceled:
      return (
        <>
          <CircleIcon color="red" />
          Отменена
        </>
      );
    case SESSION_STATUSES.auto_finish:
      return (
        <>
          <CircleIcon color="grey" />
          Автоматически завершена
        </>
      );
    default:
      return <>-</>;
  }
};

const propTypes = {};

function SessionsCards({
  facilityId,
  sessionsData,
  hideCustomer = false,
  hideBtn = false,
  showFacility = false,
  onStop = () => {},
  onCancel = () => {},
  onChangeTariffPlan = () => {},
}) {
  const styles = useStyles();
  const { t } = useTranslation();
  const history = useHistory();

  const isSessionsData = sessionsData && (sessionsData || []).length > 0;

  const hasCustomerShowRight = useCheckFacilityRights(RIGHT_KEYS.customerShow, facilityId);
  const hasSessionChangeTariffRight = useCheckFacilityRights(RIGHT_KEYS.sessionChangeTariff, facilityId);
  const hasSessionChangeStatusRight = useCheckFacilityRights(RIGHT_KEYS.sessionChangeStatus, facilityId);

  const hasPaymentModule = useHasFacilityModule(facilityId, MODULES_KEYS.payments);

  const createOnStopHandler = (params) => () => {
    onStop(params);
  };

  const createOnCancelHandler = (params) => () => {
    onCancel(params);
  };

  const createOnChangeTariffPlanHandler = (params) => () => {
    onChangeTariffPlan(params);
  };

  const [imageViewerNode, openImageViewer] = useImageViewer();

  return (
    <div className={styles.root}>
      {!isSessionsData && (
        <div className={styles.noDataContainer}>
          <Typography className={styles.noDataStr}>Пока журнал сессий пуст</Typography>
        </div>
      )}
      {isSessionsData && (
        <Grid container spacing={2}>
          {(sessionsData || []).map((item) => {
            const {
              id,
              number,
              facilityId: sessionFacilityId,
              facilityTitle: sessionFacilityTitle,
              customerId,
              customerName,
              startTime,
              finishTime,
              status,
              tariffPlanId,
              tariffPlanName,
              totalAmount,
              paidAmount,
              canChangeTariffPlan,
              startToken,
              finishToken,
              isPass,
              isFreeExit,
              placeName,
            } = item || {};
            const key = id;

            const currency = t('others.currency');

            const numberStr = number.replace(/(.{5})/g, '$1 ');

            const startTimeStr = moment(startTime).format('HH:mm:ss');
            const startDateStr = moment(startTime).format('DD.MM.yyyy');
            const finishTimeStr = finishTime ? moment(finishTime).format('HH:mm:ss') : '-';
            const finishDateStr = finishTime ? moment(finishTime).format('DD.MM.yyyy') : '';

            const duration = finishTime
              ? moment(finishTime).diff(moment(startTime))
              : undefined;

            const totalAmountStr = totalAmount
              ? `${(+totalAmount || 0).toLocaleString()}\xa0${currency}`
              : `0\xa0${currency}`;
            const paidAmountStr = paidAmount
              ? `${(+paidAmount || 0).toLocaleString()}\xa0${currency}`
              : `0\xa0${currency}`;

            return (
              <Grid key={key} item xs={12}>
                <Card className={styles.cardItem} elevation={2}>
                  <GridTitleBase classNameContainer={styles.firstItemContainer} title="Билет" titleXs={5}>
                    <Typography className={styles.idStr}>{numberStr}</Typography>
                  </GridTitleBase>
                  {showFacility && (
                    <GridTitleBase classNameContainer={styles.itemContainer} title="Парковка" titleXs={5}>
                      <Typography>
                        {sessionFacilityTitle}
                      </Typography>
                    </GridTitleBase>
                  )}
                  <GridTitleBase classNameContainer={styles.itemContainer} title="Доступ" titleXs={5}>
                    <div>
                      <SessionAccessToken
                        hideEdit={!customerId}
                        hasAddPhone={!isPass}
                        sessionId={id}
                        tokens={startToken}
                        onImageClick={openImageViewer}
                        facilityId={sessionFacilityId}
                      />
                      <SessionAccessToken
                        hideEdit={!customerId}
                        hasAddPhone={!isPass}
                        sessionId={id}
                        tokens={finishToken}
                        isFinish
                        onImageClick={openImageViewer}
                        facilityId={sessionFacilityId}
                      />
                    </div>
                  </GridTitleBase>
                  <GridTitleBase classNameContainer={styles.itemContainer} title="Статус" titleXs={5}>
                    <Typography className={styles.statusStr}>{getStatusContentByStatus(status)}</Typography>
                  </GridTitleBase>
                  {hasPaymentModule && (
                    <GridTitleBase classNameContainer={styles.itemContainer} title="Оплачено/Всего" titleXs={5}>
                      <Typography className={styles.price}>
                        <span>{paidAmountStr}</span>
                        {' / '}
                        {totalAmountStr}
                      </Typography>
                    </GridTitleBase>
                  )}
                  <GridTitleBase classNameContainer={styles.itemContainer} title="Тарифный план" titleXs={5}>
                    {canChangeTariffPlan && hasSessionChangeTariffRight ? (
                      <CustomLink
                        className={styles.statusStr}
                        onClick={createOnChangeTariffPlanHandler({
                          id,
                          customerId,
                          tariffPlanId,
                          facilityId: sessionFacilityId,
                        })}
                      >
                        {tariffPlanName}
                        <EditIcon fontSize="small" />
                      </CustomLink>
                    ) : (
                      <Typography>{tariffPlanName}</Typography>
                    )}
                  </GridTitleBase>
                  {!hideCustomer && (
                    <GridTitleBase classNameContainer={styles.itemContainer} title="Клиент" titleXs={5}>
                      {customerId && hasCustomerShowRight ? (
                        <CustomLink
                          href={PATH_PAGES.oneCustomerInfo
                            .replace(':facilityId', sessionFacilityId)
                            .replace(':id', customerId)}
                          onClick={(e) => {
                            e.preventDefault();
                            history.push(
                              PATH_PAGES.oneCustomerInfo.replace(':facilityId', sessionFacilityId).replace(':id', customerId),
                            );
                          }}
                        >
                          {customerName}
                        </CustomLink>
                      ) : (
                        <Typography>{customerName || 'Гость'}</Typography>
                      )}
                    </GridTitleBase>
                  )}
                  {placeName && (
                    <GridTitleBase
                      classNameContainer={styles.itemContainer}
                      title="Место"
                      titleXs={5}
                    >
                      <Typography>
                        {placeName}
                      </Typography>
                    </GridTitleBase>
                  )}
                  {!isFreeExit && (
                    <GridTitleBase classNameContainer={styles.itemContainer} title="Вьезд" titleXs={5}>
                      <Typography className={styles.timeStr}>
                        {startTimeStr}
                        {' '}
                        <span className={styles.dateStr}>{startDateStr}</span>
                      </Typography>
                    </GridTitleBase>
                  )}
                  <GridTitleBase
                    classNameContainer={styles.itemContainer}
                    title={isFreeExit ? 'Свободный выезд' : 'Выезд'}
                    titleXs={5}
                  >
                    <Typography className={styles.timeStr}>
                      {finishTimeStr}
                      {' '}
                      <span className={styles.dateStr}>{finishDateStr}</span>
                    </Typography>
                  </GridTitleBase>
                  {duration && (
                    <GridTitleBase
                      classNameContainer={styles.itemContainer}
                      title="Продолжительность"
                      titleXs={5}
                    >
                      <Typography className={styles.timeStr}>
                        {getHumanizeDurationShort(duration, ['d', 'h', 'm'])}
                      </Typography>
                    </GridTitleBase>
                  )}
                  {status === SESSION_STATUSES.active && !hideBtn && hasSessionChangeStatusRight && (
                    <Grid container spacing={2} className={styles.btnsContainer}>
                      <Grid item xs={6}>
                        <CustomBtn
                          variant="outlined"
                          startIcon={<StopIcon />}
                          className={styles.btn}
                          onClick={createOnStopHandler({ id, customerId, facilityId: sessionFacilityId })}
                        >
                          Остановить
                        </CustomBtn>
                      </Grid>
                      <Grid item xs={6}>
                        <CustomBtn
                          variant="outlined"
                          startIcon={<BlockIcon />}
                          className={styles.btn}
                          onClick={createOnCancelHandler({ id, customerId, facilityId: sessionFacilityId })}
                        >
                          Отменить
                        </CustomBtn>
                      </Grid>
                    </Grid>
                  )}
                </Card>
              </Grid>
            );
          })}
        </Grid>
      )}
      {imageViewerNode}
    </div>
  );
}

SessionsCards.propTypes = propTypes;

export default SessionsCards;
