import React, { useEffect, useMemo } from 'react';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { CircularProgress, Grid } from '@material-ui/core';
import Container from '@material-ui/core/Container';
import IconButton from '@material-ui/core/IconButton';
import InputAdornment from '@material-ui/core/InputAdornment';
import Typography from '@material-ui/core/Typography';
import CloseIcon from '@material-ui/icons/Close';
import clone from 'lodash/clone';
import moment from 'moment-timezone';

import CircularIndeterminate from '@parkly/shared/components/atoms/CircularIndeterminate';
import CustomBtn from '@parkly/shared/components/atoms/CustomBtn';
import CustomLink from '@parkly/shared/components/atoms/CustomLink';
import CustomTextInput from '@parkly/shared/components/atoms/CustomTextInput';
import HeaderWithBackBtn from '@parkly/shared/components/molecules/HeaderWithBackBtn';
import ItemSelectors from '@parkly/shared/components/molecules/ItemSelectors';
import { MAX_PHONE_NUMBER_LENGTH } from '@parkly/shared/config/constants';
import {
  cleanPhone,
  formatPhoneNumber,
  formatPhoneNumberInput,
  getApiErrorMsg,
  useFormFields,
  useModal,
} from '@parkly/shared/helpers';

import {
  cancelPassAction,
  clearPassesCacheAction,
  getActivePassSettingsAction,
  getOnePassAction,
  payPassAction,
  updatePassesAction,
} from 'actions/passes';
import { usePersonalAccountsForCharge } from 'api/query/personalAccounts';
import DateTimeRangePicker from 'components/molecules/DateTimeRangePicker';
import CancelPassModalContent from 'components/organisms/CancelPassModalContent';
import ManualPayPassModalContent from 'components/organisms/ManualPayPassModalContent';
import PassPlaceSelect from 'components/organisms/PassPlaceSelect';
import RenewPassModalContent from 'components/organisms/RenewPassModalContent';
import {
  MODULES_KEYS,
  PASS_BOOK_PLACE_TYPES, PASS_PAYMENT_METHODS,
  PASS_REQ_TYPE_NEED,
  PASS_REQ_TYPES,
  PASS_REQ_TYPES_MAP,
  PASSES_STATUSES,
  PASSES_STATUSES_MAP,
  PATH_PAGES,
  RIGHT_KEYS,
} from 'config/constants';
import { useCheckFacilityRights, useCommonRights, useHasFacilityModule } from 'helpers/hooks';
import { useCurrentFacilityMatch } from 'helpers/routerHooks';

import { useStyles } from './styles';

const PASSES_STATUSES_ITEMS = [
  { id: PASSES_STATUSES.draft, title: PASSES_STATUSES_MAP[PASSES_STATUSES.draft] },
  { id: PASSES_STATUSES.awaitingPayment, title: PASSES_STATUSES_MAP[PASSES_STATUSES.awaitingPayment] },
  // { id: PASSES_STATUSES.active, title: PASSES_STATUSES_MAP[PASSES_STATUSES.active] },
  // { id: PASSES_STATUSES.canceled, title: PASSES_STATUSES_MAP[PASSES_STATUSES.canceled] },
  // { id: PASSES_STATUSES.finished, title: PASSES_STATUSES_MAP[PASSES_STATUSES.finished] },
];

function formatValue(name, value) {
  if (name === 'phone') {
    return formatPhoneNumberInput(value);
  }

  return value;
}

function getOnePass({
  onePassState,
  match,
}) {
  const passId = ((match || {}).params || {}).id;

  const { list } = onePassState || {};
  const currentPass = (list || []).find(({ id } = {}) => {
    // eslint-disable-next-line
    const isNeeded = ('' + id) === ('' + passId);

    return isNeeded;
  });

  return {
    passId,
    currentPass,
  };
}

function OnePassPage({
  match,

  getOnePassReg,
  onePassState,

  setPassesReg,
  setPassState,

  cancelPassState,

  clearPassesCacheReg,
}) {
  const styles = useStyles();
  const history = useHistory();
  useCurrentFacilityMatch(PATH_PAGES.passes);
  const { params, path } = match || {};
  const {
    id: passId,
    facilityId,
  } = params || {};

  const isNewMode = !passId && path === PATH_PAGES.addPass;

  const [fields, errors, onChange, setErrors, updateState] = useFormFields({
    initValues: {
      customer: '',
      customerId: '',
      passTypeId: '',
      personalAccountId: '',
      placeId: '',
      startTime: null,
      finishTime: null,
      status: PASSES_STATUSES.draft,
      reqValues: [],
      optionalReqValues: [],
      additionalReqValues: [],
    },
    formatValueFunc: formatValue,
  });

  const hasPassUpdateRight = useCheckFacilityRights(RIGHT_KEYS.passUpdate, facilityId);
  const hasPassCancelRight = useCheckFacilityRights(RIGHT_KEYS.passCancel, facilityId);
  const hasPassManualPaymentRight = useCheckFacilityRights(RIGHT_KEYS.passManualPayment, facilityId);
  const hasPersonalAccountModule = useHasFacilityModule(facilityId, MODULES_KEYS.personalAccounts);
  const hasPersonalAccountChargeRight = useCommonRights(RIGHT_KEYS.personalAccountsCharge);

  useEffect(() => {
    const {
      currentPass,
    } = getOnePass({
      onePassState,
      match,
    });

    const { loading: isOneCustomerLoading } = onePassState || {};
    if (!isOneCustomerLoading && !currentPass && !isNewMode) {
      getOnePassReg({
        id: passId,
        facilityId,
      });
    }

    if (currentPass && !isNewMode) {
      const { error } = currentPass || {};

      if (error) {
        return;
      }

      const reqValues = [];
      const optionalReqValues = [];
      const additionalReqValues = [];

      Object.entries(currentPass.passType.requiredData).forEach((entry) => {
        const [key, value] = entry;

        if (key.includes('additional')) {
          return;
        }

        const reqValue = currentPass.requiredData.find(({ type }) => type === key);

        if (!reqValue) {
          return;
        }

        let newValue = '';
        if ([PASS_REQ_TYPES.phone, PASS_REQ_TYPES.additionalPhone].includes(key)) {
          newValue = formatPhoneNumber(reqValue.value);
        } else {
          newValue = reqValue.value;
        }

        if (value === PASS_REQ_TYPE_NEED.required) {
          reqValues.push({ ...reqValue, type: key, value: newValue });
        }

        if (value === PASS_REQ_TYPE_NEED.optional) {
          optionalReqValues.push({ ...reqValue, type: key, value: newValue });
        }
      });

      currentPass.requiredData.forEach(({ type, value, ...other }) => {
        if (![PASS_REQ_TYPES.additionalPhone, PASS_REQ_TYPES.additionalPlateNumber].includes(type)) {
          return;
        }

        let newValue = '';
        if ([PASS_REQ_TYPES.phone, PASS_REQ_TYPES.additionalPhone].includes(type)) {
          newValue = formatPhoneNumber(value);
        } else {
          newValue = value;
        }

        additionalReqValues.push({ type, value: newValue, ...other });
      });

      updateState({
        ...currentPass,
        reqValues,
        optionalReqValues,
        additionalReqValues,
        startTime: moment(currentPass.startTime),
        finishTime: moment(currentPass.finishTime),
      });
    }
  }, [facilityId, getOnePassReg, isNewMode, match, onePassState, passId, updateState]);

  useEffect(() => {
    const { error } = setPassState || {};
    if (error) {
      const { response } = error || {};
      const { data } = response || {};
      const backErrors = (data || {}).errors || {};

      setErrors({
        ...backErrors,
      });
    }
  }, [setErrors, setPassState]);

  const { data: personalAccountsData, isLoading: isPersonalAccountsLoading } = usePersonalAccountsForCharge({
    variables: {},
    enabled: hasPersonalAccountModule && hasPersonalAccountChargeRight,
  });

  const personalAccountItems = useMemo(() => (personalAccountsData || []).map(({ id, name, number }) => ({
    id,
    title: name || number,
  })), [personalAccountsData]);

  const countAdditionalPhone = (fields.additionalReqValues || [])
    .filter((({ type }) => type === PASS_REQ_TYPES.additionalPhone)).length;
  const countAdditionalPlateNumbers = (fields.additionalReqValues || [])
    .filter((({ type }) => type === PASS_REQ_TYPES.additionalPlateNumber)).length;

  const onSubmit = (e) => {
    e.preventDefault();
    e.nativeEvent.preventDefault();

    if (!hasPassUpdateRight) {
      return;
    }

    if (!fields.startTime || !fields.finishTime) {
      setErrors({
        startTime: true,
        finishTime: true,
      });
      return;
    }

    setPassesReg({
      id: passId,
      facilityId,
      customerId: fields.customerId,
      passTypeId: fields.passTypeId,
      placeId: fields.placeId || null,
      startTime: fields.startTime ? fields.startTime : null,
      finishTime: fields.finishTime ? fields.finishTime : null,
      status: fields.status,
      personalAccountId: fields.personalAccountId,
      reqValues: fields.reqValues
        .concat(fields.optionalReqValues)
        .concat(fields.additionalReqValues)
        .filter(({ value }) => !!value)
        .map(({ type, value, ...other }) => ({
          type,
          value: [PASS_REQ_TYPES.phone, PASS_REQ_TYPES.additionalPhone].includes(type) ? cleanPhone(value) : value,
          ...other,
        })),
    }, history);
  };

  const {
    currentPass,
  } = getOnePass({
    onePassState,
    match,
  });

  const {
    status,
    url,
    placeId,
    areaId,
    canBeRenewal,
    passType,
  } = currentPass || {};

  const { bookPlaceType, paymentMethod } = passType || {};

  const [payModalNode, openPayModal, hidePayModal] = useModal({
    content: <ManualPayPassModalContent
      facilityId={facilityId}
      passId={passId}
      price={fields.price}
      onComplete={() => {
        hidePayModal();
        clearPassesCacheReg();
      }}
      onClose={() => {
        hidePayModal();
      }}
    />,
  });

  const [renewModalNode, openRenewModal, hideRenewModal] = useModal({
    content: <RenewPassModalContent
      facilityId={facilityId}
      passId={passId}
      onComplete={() => {
        hideRenewModal();
        clearPassesCacheReg();
      }}
      onClose={() => {
        hideRenewModal();
      }}
    />,
  });

  const [cancelModalNode, openCancelModal, hideCancelModal] = useModal({
    content: <CancelPassModalContent
      facilityId={facilityId}
      passId={passId}
      availableCancelPayment={status === PASSES_STATUSES.active}
      onComplete={() => {
        hideCancelModal();
        clearPassesCacheReg();
      }}
      onClose={() => {
        hideCancelModal();
      }}
    />,
  });

  const {
    loading: isSaveLoading,
  } = setPassState || {};

  const { loading: isCancelLoading } = cancelPassState || {};

  if (
    !isNewMode && !currentPass
    && (onePassState || {}).loading
  ) {
    return (
      <CircularIndeterminate style={{ minHeight: 600 }} />
    );
  }

  const { error: currentPassError } = currentPass || {};

  if (currentPassError) {
    const currentPassErrorMsg = getApiErrorMsg(currentPassError);
    return (
      <Container className={styles.container}>
        <HeaderWithBackBtn
          title="Абонемент"
        />
        <Typography>{currentPassErrorMsg}</Typography>
      </Container>
    );
  }

  const updateAvailable = [PASSES_STATUSES.active, PASSES_STATUSES.draft, PASSES_STATUSES.awaitingPayment].includes(status)
    || !hasPassUpdateRight;

  return (
    <Container className={styles.container} maxWidth="md">
      <HeaderWithBackBtn
        title={isNewMode ? 'Добавление абонемента' : 'Абонемент'}
      />
      <form noValidate className={styles.form} onSubmit={onSubmit}>
        <Grid container spacing={2}>
          <Grid item container spacing={2} xs={12}>
            <Grid item xs={12} md={7}>
              <CustomTextInput
                required
                className={styles.inputItem}
                value={fields.passType ? fields.passType.name : ''}
                disabled
                label="Тип абонемента"
              />
            </Grid>
            <Grid item xs={12} md={8}>
              <DateTimeRangePicker
                label="Период"
                required
                clearable
                error={!!errors.startTime || !!errors.finishTime}
                local={{
                  fromDate: 'Начало абонемента',
                  toDate: 'Конец абонемента',
                }}
                rangesType="ticket"
                // smartMode
                autoApply
                initStart={fields.startTime}
                initEnd={fields.finishTime}
                onChange={({ start, end }) => {
                  updateState({
                    startTime: start,
                    finishTime: end,
                  });
                  setErrors({
                    startTime: false,
                    finishTime: false,
                  });
                }}
              />
            </Grid>
            {hasPersonalAccountModule && paymentMethod === PASS_PAYMENT_METHODS.personalAccount && (
              <Grid item xs={12} md={8}>
                <ItemSelectors
                  classNameForm={styles.selectorsForm}
                  classNameLabel={styles.selectorsLabel}
                  className={styles.selector}
                  disabled={!hasPassUpdateRight || (personalAccountItems || []).length <= 1}
                  label="Лицевой счет"
                  items={personalAccountItems}
                  error={!!errors.personalAccountId}
                  name="personalAccountId"
                  onChange={onChange}
                  currentValue={fields.personalAccountId}
                />
              </Grid>
            )}
            {fields.passType && fields.passType.isBookPlace && (
              <Grid item xs={12} md={10}>
                <PassPlaceSelect
                  disabled={!updateAvailable}
                  facilityId={facilityId}
                  passTypeId={fields.passTypeId}
                  initPlaceId={placeId}
                  initAreaId={areaId}
                  personalAccountId={fields.personalAccountId}
                  selTitle={fields.placeTitle}
                  onChange={(id, title) => updateState({ placeId: id, placeTitle: title })}
                  enableReserved={bookPlaceType === PASS_BOOK_PLACE_TYPES.session}
                />
              </Grid>
            )}
            <Grid item xs={12} md={6}>
              {([PASSES_STATUSES.awaitingPayment, PASSES_STATUSES.draft].includes(status)) && (
                <ItemSelectors
                  classNameForm={styles.selectorsForm}
                  classNameLabel={styles.selectorsLabel}
                  className={styles.selector}
                  disabled={!updateAvailable}
                  label="Статус"
                  items={PASSES_STATUSES_ITEMS}
                  error={!!errors.status}
                  name="status"
                  onChange={onChange}
                  currentValue={fields.status}
                />
              )}
              {![PASSES_STATUSES.awaitingPayment, PASSES_STATUSES.draft].includes(status) && (
                <CustomTextInput
                  className={styles.inputItem}
                  value={PASSES_STATUSES_MAP[fields.status]}
                  name="status"
                  disabled
                  label="Статус"
                />
              )}
            </Grid>
            <Grid item xs={12} md={6} className={styles.link}>
              {!isNewMode && url && (
                <CustomLink href={url} target="_blank">
                  Ссылка для оплаты/продления
                </CustomLink>
              )}
            </Grid>
            <Grid item xs={12} md={7}>
              <CustomTextInput
                required
                className={styles.inputItem}
                value={fields.customerName || ''}
                name="customerName"
                error={!!errors.customerId}
                disabled
                label="Клиент"
              />
            </Grid>
            <Grid item xs={12}>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Typography>Обязательные данные:</Typography>
                </Grid>
                {fields && (fields.reqValues || []).map(({ value, type }, reqIndex) => (
                  <Grid key={`req_value_${reqIndex}`} item xs={12} sm={6} md={4}>
                    <CustomTextInput
                      required={!type.includes('additional')}
                      className={styles.inputItem}
                      error={errors[`requiredData.${reqIndex}.value`] || errors[`requiredData.${type}`] || false}
                      label={PASS_REQ_TYPES_MAP[type]}
                      value={value || ''}
                      autoComplete="off"
                      disabled={!updateAvailable}
                      inputProps={{
                        maxLength: [PASS_REQ_TYPES.phone, PASS_REQ_TYPES.additionalPhone].includes(type)
                          ? MAX_PHONE_NUMBER_LENGTH : 250,
                      }}
                      onChange={(e) => {
                        const { target } = e || {};
                        const {
                          value: newValue,
                        } = target || {};

                        const newReqValues = clone(fields.reqValues);

                        if ([PASS_REQ_TYPES.phone, PASS_REQ_TYPES.additionalPhone].includes(type)) {
                          newReqValues[reqIndex].value = formatPhoneNumberInput(newValue);
                        } else {
                          newReqValues[reqIndex].value = newValue;
                        }

                        updateState({
                          reqValues: newReqValues,
                        });
                        setErrors({
                          [`requiredData.${reqIndex}.value`]: false,
                          [`requiredData.${type}`]: false,
                        });
                      }}
                    />
                  </Grid>
                ))}
                <Grid item xs={12}>
                  <Typography>Дополнительные данные:</Typography>
                </Grid>
                {fields && (fields.optionalReqValues || []).map(({ value, type }, reqIndex) => (
                  <Grid key={`optional_req_value_${reqIndex}`} item xs={12} sm={6} md={4}>
                    <CustomTextInput
                      required={false}
                      className={styles.inputItem}
                      error={errors[`requiredData.${reqIndex}.value`] || errors[`requiredData.${type}`] || false}
                      label={PASS_REQ_TYPES_MAP[type]}
                      value={value || ''}
                      autoComplete="off"
                      disabled={!updateAvailable}
                      inputProps={{
                        maxLength: [PASS_REQ_TYPES.phone, PASS_REQ_TYPES.additionalPhone].includes(type)
                          ? MAX_PHONE_NUMBER_LENGTH : 250,
                      }}
                      InputProps={{
                        endAdornment: type.includes('additional') ? (
                          <InputAdornment position="end">
                            <IconButton
                              disabled={!updateAvailable}
                              size="small"
                              onClick={() => {
                                const newReqValues = clone(fields.optionalReqValues);
                                newReqValues.splice(reqIndex, 1);
                                updateState({
                                  optionalReqValues: newReqValues,
                                });
                              }}
                            >
                              <CloseIcon fontSize="small" />
                            </IconButton>
                          </InputAdornment>
                        ) : null,
                      }}
                      onChange={(e) => {
                        const { target } = e || {};
                        const {
                          value: newValue,
                        } = target || {};

                        const newReqValues = clone(fields.optionalReqValues);

                        if ([PASS_REQ_TYPES.phone, PASS_REQ_TYPES.additionalPhone].includes(type)) {
                          newReqValues[reqIndex].value = formatPhoneNumberInput(newValue);
                        } else {
                          newReqValues[reqIndex].value = newValue;
                        }

                        updateState({
                          optionalReqValues: newReqValues,
                        });
                        setErrors({
                          [`requiredData.${reqIndex}.value`]: false,
                          [`requiredData.${type}`]: false,
                        });
                      }}
                    />
                  </Grid>
                ))}
                {fields && (fields.additionalReqValues || []).map(({ value, type }, reqIndex) => (
                  <Grid key={`additional_req_value_${reqIndex}`} item xs={12} sm={6} md={4}>
                    <CustomTextInput
                      required={false}
                      className={styles.inputItem}
                      error={errors[`requiredData.${reqIndex}.value`] || errors[`requiredData.${type}`] || false}
                      label={PASS_REQ_TYPES_MAP[type]}
                      value={value || ''}
                      autoComplete="off"
                      disabled={!updateAvailable}
                      inputProps={{
                        maxLength: [PASS_REQ_TYPES.phone, PASS_REQ_TYPES.additionalPhone].includes(type)
                          ? MAX_PHONE_NUMBER_LENGTH : 250,
                      }}
                      InputProps={{
                        endAdornment: type.includes('additional') ? (
                          <InputAdornment position="end">
                            <IconButton
                              disabled={!updateAvailable}
                              size="small"
                              onClick={() => {
                                const newReqValues = clone(fields.additionalReqValues);
                                newReqValues.splice(reqIndex, 1);
                                updateState({
                                  additionalReqValues: newReqValues,
                                });
                              }}
                            >
                              <CloseIcon fontSize="small" />
                            </IconButton>
                          </InputAdornment>
                        ) : null,
                      }}
                      onChange={(e) => {
                        const { target } = e || {};
                        const {
                          value: newValue,
                        } = target || {};

                        const newReqValues = clone(fields.additionalReqValues);

                        if ([PASS_REQ_TYPES.phone, PASS_REQ_TYPES.additionalPhone].includes(type)) {
                          newReqValues[reqIndex].value = formatPhoneNumberInput(newValue);
                        } else {
                          newReqValues[reqIndex].value = newValue;
                        }

                        updateState({
                          additionalReqValues: newReqValues,
                        });
                        setErrors({
                          [`requiredData.${reqIndex}.value`]: false,
                          [`requiredData.${type}`]: false,
                        });
                      }}
                    />
                  </Grid>
                ))}
                {updateAvailable && (
                  <Grid item xs={12}>
                    <Grid container spacing={2}>
                      {passType && passType.needAdditionalPhone && countAdditionalPhone < 3 && (
                        <Grid item>
                          <CustomLink
                            onClick={() => {
                              const newReqValues = clone(fields.additionalReqValues);
                              newReqValues.push({ type: PASS_REQ_TYPES.additionalPhone, value: '' });

                              updateState({
                                additionalReqValues: newReqValues,
                              });
                            }}
                          >
                            Добавить доп. номер телефона
                          </CustomLink>
                        </Grid>
                      )}
                      {passType && passType.needAdditionalPlateNumber && countAdditionalPlateNumbers < 4 && (
                        <Grid item>
                          <CustomLink
                            onClick={() => {
                              const newReqValues = clone(fields.additionalReqValues);
                              newReqValues.push({ type: PASS_REQ_TYPES.additionalPlateNumber, value: '' });

                              updateState({
                                additionalReqValues: newReqValues,
                              });
                            }}
                          >
                            Добавить доп. гос номер
                          </CustomLink>
                        </Grid>
                      )}
                    </Grid>
                  </Grid>
                )}
              </Grid>
            </Grid>
          </Grid>
          <Grid container className={styles.btnsContainer} spacing={2} item xs={12}>
            <Grid item xs={3}>
              <CustomBtn
                type="submit"
                className={styles.btnSubmit}
                btnType="primaryBlue"
                disabled={!updateAvailable}
              >
                {isNewMode ? 'Добавить' : 'Сохранить'}
                {isSaveLoading && (
                  <CircularProgress
                    style={{ marginLeft: 5 }}
                    size={20}
                    color="inherit"
                  />
                )}
              </CustomBtn>
            </Grid>
            {/* {!isNewMode && hasPassCancelRight && [PASSES_STATUSES.draft, PASSES_STATUSES.awaitingPayment, PASSES_STATUSES.active].includes(status) && ( */}
            {/*  <Grid item> */}
            {/*    <CustomBtn */}
            {/*      className={styles.btnSubmit} */}
            {/*      btnType="primaryRed" */}
            {/*      onClick={openCancelModal} */}
            {/*      disabled={!hasPassCancelRight} */}
            {/*    > */}
            {/*      <CancelIcon style={{ marginRight: 5 }} fontSize="small" /> */}
            {/*      Отменить */}
            {/*    </CustomBtn> */}
            {/*  </Grid> */}
            {/* )} */}
            {/* { !isNewMode && hasPassManualPaymentRight && status === PASSES_STATUSES.awaitingPayment && ( */}
            {/*  <Grid item> */}
            {/*    <CustomBtn */}
            {/*      className={styles.btnSubmit} */}
            {/*      // disabled={isCreateLoading} */}
            {/*      onClick={openPayModal} */}
            {/*    > */}
            {/*      <PaymentIcon style={{ marginRight: 5 }} fontSize="small" /> */}
            {/*      Добавить оплату */}
            {/*      {isSaveLoading && ( */}
            {/*        <CircularProgress */}
            {/*          style={{ marginLeft: 5 }} */}
            {/*          size={20} */}
            {/*          color="inherit" */}
            {/*        /> */}
            {/*      )} */}
            {/*    </CustomBtn> */}
            {/*  </Grid> */}
            {/* )} */}
            {/* {hasPassManualPaymentRight && canBeRenewal && ( */}
            {/*  <Grid item> */}
            {/*    <CustomBtn */}
            {/*      className={styles.btnSubmit} */}
            {/*      // disabled={isCreateLoading} */}
            {/*      onClick={openRenewModal} */}
            {/*    > */}
            {/*      <FastForwardIcon style={{ marginRight: 5 }} fontSize="small" /> */}
            {/*      Продлить */}
            {/*      {isSaveLoading && ( */}
            {/*        <CircularProgress */}
            {/*          style={{ marginLeft: 5 }} */}
            {/*          size={20} */}
            {/*          color="inherit" */}
            {/*        /> */}
            {/*      )} */}
            {/*    </CustomBtn> */}
            {/*  </Grid> */}
            {/* )} */}
          </Grid>
        </Grid>
      </form>
      {payModalNode}
      {renewModalNode}
      {cancelModalNode}
    </Container>
  );
}

function mapStateToProps(state) {
  const { passes } = state || {};
  const {
    onePass,
    setPass,
    activePassSettings,
    cancelPass,
    payPass,
  } = passes || {};

  return {
    onePassState: onePass,
    setPassState: setPass,
    cancelPassState: cancelPass,
    payPassState: payPass,
    activePassSettingsState: activePassSettings,
  };
}

const ConnectedOneCustomerPage = connect(
  mapStateToProps,
  {
    getOnePassReg: getOnePassAction,
    setPassesReg: updatePassesAction,
    getActivePassSettingsReg: getActivePassSettingsAction,
    cancelPassReg: cancelPassAction,
    payPassReg: payPassAction,
    clearPassesCacheReg: clearPassesCacheAction,
  },
)(OnePassPage);

export default ConnectedOneCustomerPage;
