import React, {
  useCallback, useEffect, useMemo, useRef, useState,
} from 'react';
import DateTimeRangeContainer from 'react-advanced-datetimerange-picker';
import { Popover } from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';
import InputAdornment from '@material-ui/core/InputAdornment';
import Typography from '@material-ui/core/Typography';
import CloseIcon from '@material-ui/icons/Close';
import { bindPopover } from 'material-ui-popup-state';
import { usePopupState } from 'material-ui-popup-state/hooks';
import moment from 'moment-timezone';

import CustomTextInput from '@parkly/shared/components/atoms/CustomTextInput';

import './styles.css';

const styleDateTimePicker = {
  fromDot: { backgroundColor: '#00D88A' },
  toDot: { backgroundColor: '#F34E4E' },
  fromDate: {
    color: 'white',
    backgroundColor: '#00D88A',
  },
  toDate: { color: 'white', backgroundColor: '#F34E4E' },
  customRangeButtons: { color: '#1D3262', backgroundColor: '#ECEFF3' },
  customRangeSelected: { color: 'white', backgroundColor: '#004BF6' },
  applyButton: { color: 'white', backgroundColor: '#004BF6' },
  cancelButton: { color: 'white', backgroundColor: '#004BF6' },
  betweenDates: { color: '#1D3262' },
  standaloneLayout: {
    display: 'flex', 'overflow-x': 'auto', position: 'relative', color: '#1D3262',
  },
};

const LOCAL = {
  format: 'DD.MM.YYYY HH:mm',
  sundayFirst: false,
  days: ['Пн', 'Вт', 'Ср', 'Чт', 'Пт', 'Сб', 'Вс'],
  months: [
    'Январь',
    'Февраль',
    'Март',
    'Апрель',
    'Май',
    'Июнь',
    'Июль',
    'Август',
    'Сентябрь',
    'Октябрь',
    'Ноябрь',
    'Декабрь',
  ],
  fromDate: 'Начало периода',
  toDate: 'Конец периода',
  selectingFrom: 'Выбор начала',
  selectingTo: 'Выбор конца',
  maxDate: 'Максимальная дата',
  close: 'Закрыть',
  apply: 'Применить',
  cancel: 'Отмена',
};

function getRangesForType(type) {
  switch (type) {
    case 'ticket':
      return {
        Сегодня: [moment().startOf('day'), moment().endOf('day')],
        Завтра: [moment().startOf('day').add(1, 'days'), moment().endOf('day').add(2, 'days')],
        Сутки: [moment(), moment().add(1, 'days')],
        Неделя: [moment(), moment().add(1, 'week')],
        Месяц: [moment(), moment().add(1, 'month')],
        Год: [moment(), moment().add(1, 'year')],
      };
    case 'filters':
      return {
        Сегодня: [moment().startOf('day'), moment().endOf('day')],
        Вчера: [moment().startOf('day').subtract(1, 'days'), moment().startOf('day').subtract(1, 'seconds')],
        Сутки: [moment().subtract(1, 'days'), moment()],
        Неделя: [moment().subtract(1, 'week'), moment()],
        Месяц: [moment().subtract(1, 'month'), moment()],
        Год: [moment().subtract(1, 'year'), moment()],
      };
    default:
      return {};
  }
}

function getInputValue(start, end, inputFormat) {
  if (!start && !end) {
    return '';
  }

  if (!start && end) {
    return `- ${end.format(inputFormat)}`;
  }

  if (start && !end) {
    return `${start.format(inputFormat)} -`;
  }

  return `${start.format(inputFormat)} - ${end.format(inputFormat)}`;
}

export default function DateTimeRangePicker({
  id = 'dateTimeRangePicker',
  initStart,
  initEnd,
  label,
  rangesType = 'ticket',
  autoApply = false,
  smartMode = false,
  pastSearchFriendly = false,
  maxDate,
  onChange = () => {},
  inputFormat = 'DD.MM.YYYY HH:mm',
  local = {},
  error = false,
  errorText = '',
  required = false,
  clearable = false,
}) {
  const [start, setStart] = useState(initStart);
  const [end, setEnd] = useState(initEnd);
  const [inputValue, setInputValue] = useState(getInputValue(initStart, initEnd, inputFormat));
  const containerRef = useRef();

  const popupState = usePopupState({
    variant: 'popover',
    popupId: `dateTimeRangePickerPopover-${id}`,
  });
  const { isOpen } = popupState || {};

  useEffect(() => {
    if (initStart && initEnd) {
      setStart(initStart);
      setEnd(initEnd);
      setInputValue(getInputValue(initStart, initEnd, inputFormat));
    }
  }, [initStart, initEnd, inputFormat]);

  const ranges = useMemo(() => getRangesForType(rangesType), [rangesType]);

  const localMerge = useMemo(() => ({ ...LOCAL, ...local }), [local]);

  const applyCallback = useCallback((s, e) => {
    setStart(s);
    setEnd(e);

    onChange({ start: s, end: e });
    setInputValue(getInputValue(s, e, inputFormat));

    if (!autoApply) {
      popupState.close();
    }
  }, [autoApply, inputFormat, onChange, popupState]);

  const rangeCallback = useCallback(() => {}, []);

  const handleOnFocus = useCallback((e) => {
    if (!start || !end) {
      const newStart = moment();
      const newEnd = moment();

      setStart(newStart);
      setEnd(newEnd);

      if (autoApply) {
        onChange({ start: newStart, end: newEnd });
        setInputValue(getInputValue(newStart, newEnd, inputFormat));
      }
    }

    e.preventDefault();
    e.stopPropagation();
    e.persist();
    popupState.open(e);
  }, [start, end, popupState, autoApply, onChange, inputFormat]);

  const handleOnClear = useCallback((e) => {
    e.preventDefault();
    e.stopPropagation();

    setStart(null);
    setEnd(null);

    onChange({ start: null, end: null });
    setInputValue('');
  }, [onChange]);

  return (
    <>
      <div onClick={handleOnFocus}>
        <CustomTextInput
          style={{ margin: 0 }}
          type="text"
          label={label}
          value={inputValue}
          error={error}
          required={required}
          InputProps={{
            endAdornment: clearable && inputValue ? (
              <InputAdornment position="end">
                <IconButton
                  size="small"
                  onClick={handleOnClear}
                  edge="end"
                >
                  <CloseIcon htmlColor="#7E8AA7" />
                </IconButton>
              </InputAdornment>
            ) : undefined,
          }}
        />
        {error && errorText && (
          <Typography style={{ color: 'red', whiteSpace: 'break-spaces' }}>{errorText}</Typography>
        )}
      </div>
      {isOpen && (
        <Popover
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'left',
          }}
          {...bindPopover(popupState)}
        >
          <div id="dateTimeRangePicker">
            <DateTimeRangeContainer
              ref={containerRef}
              ranges={ranges}
              start={start}
              end={end}
              style={styleDateTimePicker}
              local={localMerge}
              maxDate={maxDate}
              applyCallback={applyCallback}
              rangeCallback={rangeCallback}
              autoApply={autoApply}
              smartMode={smartMode}
              pastSearchFriendly={pastSearchFriendly}
              standalone
            />
          </div>
        </Popover>
      )}
    </>

  );
}
