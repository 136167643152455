import React, { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Tooltip } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Typography from '@material-ui/core/Typography';
import CancelIcon from '@material-ui/icons/Cancel';
import FastForwardIcon from '@material-ui/icons/FastForward';
import PaymentIcon from '@material-ui/icons/Payment';
import StopIcon from '@material-ui/icons/Stop';
import Pagination from '@material-ui/lab/Pagination';
import { useQueryClient } from '@tanstack/react-query';
import isEmpty from 'lodash/isEmpty';
import moment from 'moment-timezone';

import CircularIndeterminate from '@parkly/shared/components/atoms/CircularIndeterminate';
import CustomLink from '@parkly/shared/components/atoms/CustomLink';
import CircleIcon from '@parkly/shared/components/atoms/icons/CircleIcon';
import SortedBigFirstIcon from '@parkly/shared/components/atoms/icons/SortedBigFirstIcon';
import SortedSmallFirstIcon from '@parkly/shared/components/atoms/icons/SortedSmallFirstIcon';
import CustomDialog from '@parkly/shared/components/templates/CustomDialog';
import { useModal } from '@parkly/shared/helpers';
import useConfirm from '@parkly/shared/helpers/confirm';

import { cancelPassAction, stopSessionsPassAction } from 'actions/passes';
import CancelPassModalContent from 'components/organisms/CancelPassModalContent';
import ManualPayPassModalContent from 'components/organisms/ManualPayPassModalContent';
import RenewPassModalContent from 'components/organisms/RenewPassModalContent';
import {
  PASS_PAYMENT_METHODS,
  PASSES_STATUSES,
  PASSES_STATUSES_COLOR_MAP,
  PASSES_STATUSES_MAP,
  PATH_PAGES,
  RIGHT_KEYS,
} from 'config/constants';
import { useCheckFacilityRights } from 'helpers/hooks';

import ReqValues from '../../../molecules/ReqValues';

import { useStyles } from './styles';

function getNextSortStatus(status) {
  if (!status) {
    return 'sort';
  }

  if (status === 'sort') {
    return 'sortReverse';
  }

  return null;
}

function getHeaders({
  t,
  hideCustomer,
  hideReqValues,
  showFacility,
}) {
  const headers = [
    {
      id: 'id',
      label: 'Номер',
    },
    showFacility ? {
      id: 'facility',
      label: 'Парковка',
    } : null,
    {
      id: 'startTime',
      label: 'Время начала',
    },
    {
      isSorted: true,
      id: 'finishTime',
      label: 'Время окончания',
    },
    {
      id: 'pass_type',
      label: 'Тип',
    },
    !hideCustomer
      ? {
        id: 'customer',
        label: 'Клиент',
      } : null,
    {
      id: 'placeName',
      label: 'Место',
    },
    !hideReqValues
      ? {
        id: 'tokens',
        label: 'Доступы',
      } : null,
    {
      id: 'status',
      label: 'Статус',
    },
    {
      id: 'actions',
      label: '',
    },
  ];

  return headers.filter((item) => !!item);
}

const getStatusNode = ({ status }, styles, key) => {
  const str = PASSES_STATUSES_MAP[status] || '-';

  const color = PASSES_STATUSES_COLOR_MAP[status] || 'grey';

  return (
    <Typography key={`${key}-${status}-${color}`} className={styles.statusStr}>
      <CircleIcon color={color} />
      {str}
    </Typography>
  );
};

function PassesContent({
  facilityId,
  isLoading = false,
  passesData = [],
  passesMeta,

  showPagination = false,
  onPageChange = () => {},
  sortingSettingsChange = () => {},
  sorting = {},
  hideCustomer = false,
  hideReqValues = false,
  showFacility = false,

  onNeedUpdate = () => {},
  stopSessionsPassReg,

  showIfNotEmpty = false,
}) {
  const { t } = useTranslation();
  const styles = useStyles();
  const history = useHistory();
  const queryClient = useQueryClient();
  const confirm = useConfirm();

  const hasPassUpdateRight = useCheckFacilityRights(RIGHT_KEYS.passUpdate, facilityId);
  const hasPassCancelRight = useCheckFacilityRights(RIGHT_KEYS.passCancel, facilityId);
  const hasPassManualPaymentRight = useCheckFacilityRights(RIGHT_KEYS.passManualPayment, facilityId);
  const hasSessionStopRight = useCheckFacilityRights(RIGHT_KEYS.sessionChangeStatus, facilityId);
  const hasPassTypeShowRight = useCheckFacilityRights(RIGHT_KEYS.passTypeShow, facilityId);
  const hasCustomerShowRight = useCheckFacilityRights(RIGHT_KEYS.customerShow, facilityId);

  const [payModalNode, openPayModal, hidePayModal] = useModal({
    content: <ManualPayPassModalContent
      onComplete={() => {
        hidePayModal();
        queryClient.invalidateQueries({ queryKey: ['sessions'] });
        queryClient.invalidateQueries({ queryKey: ['customers'] });
        queryClient.invalidateQueries({ queryKey: ['passes'] });
        onNeedUpdate();
      }}
      onClose={() => {
        hidePayModal();
      }}
    />,
  });

  const [renewPassModalNode, openRenewModal, hideRenewModal] = useModal({
    content: <RenewPassModalContent
      onComplete={() => {
        hideRenewModal();
        queryClient.invalidateQueries({ queryKey: ['sessions'] });
        queryClient.invalidateQueries({ queryKey: ['customers'] });
        queryClient.invalidateQueries({ queryKey: ['passes'] });
        onNeedUpdate();
      }}
      onClose={() => {
        hideRenewModal();
      }}
    />,
  });

  const [cancelModalNode, openCancelModal, hideCancelModal] = useModal({
    content: <CancelPassModalContent
      onComplete={() => {
        hideCancelModal();
        queryClient.invalidateQueries({ queryKey: ['sessions'] });
        queryClient.invalidateQueries({ queryKey: ['customers'] });
        queryClient.invalidateQueries({ queryKey: ['passes'] });
        onNeedUpdate();
      }}
      onClose={() => {
        hideCancelModal();
      }}
    />,
  });

  const handleStopSessionForPass = useCallback(({ passId, passFacilityId }) => {
    if (!passFacilityId && !hasSessionStopRight) {
      return;
    }

    confirm({
      title: 'Вы уверены, что хотите остановить сессию?',
      agreeTitle: 'Остановить',
      disagreeTitle: 'Отмена',
    }).then(() => {
      stopSessionsPassReg({ facilityId: passFacilityId, id: passId });
      onNeedUpdate();
      queryClient.invalidateQueries({ queryKey: ['sessions'] });
      queryClient.invalidateQueries({ queryKey: ['customers'] });
      queryClient.invalidateQueries({ queryKey: ['passes'] });
    });
  }, [hasSessionStopRight, confirm, stopSessionsPassReg, onNeedUpdate, queryClient]);

  function createHeaderClickHandler(id, isSorted) {
    return function headerClickHandler() {
      if (!id || !isSorted) {
        return;
      }
      sortingSettingsChange({
        headerNameId: id,
      });
    };
  }

  const {
    currentPage = 1,
    lastPage = 1,
  } = passesMeta || {};

  const headers = getHeaders({
    t, hideCustomer, hideReqValues, showFacility,
  });

  if (!showIfNotEmpty && isEmpty(passesData)) {
    return null;
  }

  return (
    <Grid item xs={12}>
      {isEmpty(passesData) && !isLoading && (
        <Grid item className={styles.noDataContainer} component={Paper}>
          <Typography
            className={styles.noDataStr}
          >
            Пока нет абонементов
          </Typography>
        </Grid>
      )}
      {isLoading && (
        <CircularIndeterminate style={{ minHeight: 600 }} />
      )}
      {!isLoading && !isEmpty(passesData) && (
        <TableContainer className={styles.container} component={Paper}>
          <Table
            size="small"
            stickyHeader
            aria-label="sticky table"
          >
            <TableHead>
              <TableRow>
                {headers.map(({ label, id, isSorted }) => {
                  const sortingRule = sorting[id || ''] || {};
                  const isSort = sortingRule === 'sort';
                  const isSortReverse = sortingRule === 'sortReverse';

                  return (
                    <TableCell
                      key={id}
                      align="left"
                      className={isSorted ? styles.clickable : ''}
                      onClick={createHeaderClickHandler(id, isSorted)}
                    >
                      <div className={styles.headerContainer}>
                        <Typography className={styles.headerStr}>
                          {label}
                        </Typography>
                        {isSort && (
                          <SortedSmallFirstIcon className={styles.sortIcon} />
                        )}
                        {isSortReverse && (
                          <SortedBigFirstIcon className={styles.sortIcon} />
                        )}
                      </div>
                    </TableCell>
                  );
                })}
              </TableRow>
            </TableHead>
            <TableBody>
              {(passesData || []).map((row, index, array) => {
                const {
                  id,
                  number,
                  facilityTitle: passFacilityTitle,
                  facilityId: passFacilityId,
                  passTypeName,
                  passTypeId,
                  passTypePaymentMethod,
                  customerName,
                  customerId,
                  finishTime,
                  startTime,
                  placeName,
                  status,
                  isOverdue,
                  requiredData,
                  price,
                  canBeRenewal,
                } = row || {};
                const key = id;

                const isLast = index === (array.length - 1);

                const finishTimeStr = finishTime ? moment(finishTime).format('HH:mm:ss') : '-';
                const finishDateStr = finishTime ? moment(finishTime).format('DD.MM.yyyy') : '-';
                const startTimeStr = startTime ? moment(startTime).format('HH:mm:ss') : '-';
                const startDateStr = startTime ? moment(startTime).format('DD.MM.yyyy') : '-';

                const tableRowClass = [
                  styles.tableRow,
                  isLast ? styles.tableRowLast : '',
                ];

                return (
                  <TableRow
                    key={key}
                    className={tableRowClass.join(' ')}
                  >
                    <TableCell align="left">
                      <CustomLink
                        className={styles.nameStr}
                        href={PATH_PAGES.onePassInfo.replace(':facilityId', passFacilityId).replace(':id', id)}
                        onClick={(e) => {
                          e.preventDefault();
                          history.push(PATH_PAGES.onePassInfo.replace(':facilityId', passFacilityId).replace(':id', id));
                        }}
                      >
                        {number.substring(9)}
                      </CustomLink>
                    </TableCell>
                    {showFacility && (
                      <TableCell align="left">
                        <Typography className={styles.dateStr}>
                          <Typography>{passFacilityTitle}</Typography>
                        </Typography>
                      </TableCell>
                    )}
                    <TableCell align="left">
                      <Typography className={styles.dateStr}>
                        {startDateStr}
                      </Typography>
                      <Typography className={styles.timeStr}>
                        {startTimeStr}
                      </Typography>
                    </TableCell>
                    <TableCell align="left">
                      <Typography className={styles.dateStr}>
                        {finishDateStr}
                      </Typography>
                      <Typography className={styles.timeStr}>
                        {finishTimeStr}
                      </Typography>
                    </TableCell>
                    <TableCell align="left">
                      {hasPassTypeShowRight ? (
                        <>
                          <CustomLink
                            href={PATH_PAGES.onePassSettings.replace(':facilityId', passFacilityId).replace(':passId', passTypeId)}
                            onClick={(e) => {
                              e.preventDefault();
                              history.push(PATH_PAGES.onePassSettings.replace(':facilityId', passFacilityId).replace(':passId', passTypeId));
                            }}
                          >
                            {passTypeName}
                          </CustomLink>
                          <Typography>{price && price.replace(/\s/g, ' ')}</Typography>
                        </>
                      ) : (
                        <>
                          <Typography>{passTypeName}</Typography>
                        </>
                      )}
                    </TableCell>
                    {!hideCustomer && (
                      <TableCell align="left">
                        {(hasCustomerShowRight && customerId) ? (
                          <CustomLink
                            // className={styles.nameStr}
                            href={PATH_PAGES.oneCustomerInfo.replace(':facilityId', passFacilityId).replace(':id', customerId)}
                            onClick={(e) => {
                              e.preventDefault();
                              history.push(PATH_PAGES.oneCustomerInfo.replace(':facilityId', passFacilityId).replace(':id', customerId));
                            }}
                          >
                            {customerName}
                          </CustomLink>
                        ) : (
                          <Typography>{customerName}</Typography>
                        )}
                      </TableCell>
                    )}
                    <TableCell align="left">
                      <Typography className={styles.boldStr}>
                        {placeName || '-'}
                      </Typography>
                    </TableCell>
                    {!hideReqValues && (
                      <TableCell align="left">
                        <ReqValues requiredData={requiredData} />
                      </TableCell>
                    )}
                    <TableCell align="left">
                      {getStatusNode({ status }, styles, key)}
                    </TableCell>
                    <TableCell align="right">
                      <Grid container spacing={1} justifyContent="flex-end">
                        {hasPassCancelRight && [PASSES_STATUSES.draft, PASSES_STATUSES.awaitingPayment, PASSES_STATUSES.active].includes(status) && (
                          <Grid item>
                            <Tooltip title="Отменить">
                              <IconButton
                                size="small"
                                onClick={() => {
                                  openCancelModal({ passId: id, facilityId: passFacilityId, availableCancelPayment: status === PASSES_STATUSES.active });
                                }}
                              >
                                <CancelIcon />
                              </IconButton>
                            </Tooltip>
                          </Grid>
                        )}
                        {hasPassManualPaymentRight && status === PASSES_STATUSES.awaitingPayment && passTypePaymentMethod === PASS_PAYMENT_METHODS.card && (
                          <Grid item>
                            <Tooltip title="Оплатить">
                              <IconButton
                                size="small"
                                onClick={() => {
                                  openPayModal({ passId: id, facilityId: passFacilityId });
                                }}
                              >
                                <PaymentIcon />
                              </IconButton>
                            </Tooltip>
                          </Grid>
                        )}
                        {hasPassManualPaymentRight && canBeRenewal && (
                          <Grid item>
                            <Tooltip title="Продлить">
                              <IconButton
                                size="small"
                                onClick={() => {
                                  openRenewModal({ passId: id, facilityId: passFacilityId });
                                }}
                              >
                                <FastForwardIcon />
                              </IconButton>
                            </Tooltip>
                          </Grid>
                        )}
                        {hasSessionStopRight && isOverdue && (
                          <Grid item>
                            <Tooltip title="Остановить сессию">
                              <IconButton
                                size="small"
                                onClick={() => {
                                  handleStopSessionForPass({ passId: id, passFacilityId });
                                }}
                              >
                                <StopIcon />
                              </IconButton>
                            </Tooltip>
                          </Grid>
                        )}
                      </Grid>
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
      )}
      {!isLoading && showPagination && lastPage > 1 && (
        <div className={styles.paginationContainer}>
          <Pagination
            className={styles.pagination}
            name="page"
            page={currentPage}
            onChange={onPageChange}
            count={lastPage}
            color="primary"
          />
        </div>
      )}
      {payModalNode}
      {renewPassModalNode}
      {cancelModalNode}
    </Grid>
  );
}

function mapStateToProps(state) {
  const { passes } = state || {};
  const { cancelPass } = passes || {};

  return {
    cancelPassState: cancelPass,
  };
}

const ConnectedPassesContent = connect(
  mapStateToProps,
  {
    cancelPassReg: cancelPassAction,
    stopSessionsPassReg: stopSessionsPassAction,
  },
)(PassesContent);

export default ConnectedPassesContent;
