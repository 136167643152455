import React, { useCallback, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import { CircularProgress } from '@material-ui/core';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import DeleteIcon from '@material-ui/icons/Delete';

import CircularIndeterminate from '@parkly/shared/components/atoms/CircularIndeterminate';
import CustomBtn from '@parkly/shared/components/atoms/CustomBtn';
import CustomLink from '@parkly/shared/components/atoms/CustomLink';
import CustomTextInput from '@parkly/shared/components/atoms/CustomTextInput';
import HeaderWithBackBtn from '@parkly/shared/components/molecules/HeaderWithBackBtn';
import ItemSelectors from '@parkly/shared/components/molecules/ItemSelectors';
import { useFormFields } from '@parkly/shared/helpers';

import { useDeleteQuotaMutation, useOneQuota, useSetQuotaMutation } from 'api/query/quotas';
import QuotaTargetSelectors from 'components/molecules/QuotaTargetSelectors/QuotaTargetSelectors';
import {
  PATH_PAGES, QUOTAS_TYPE_EXTRA_TARGETS, QUOTAS_TYPES, QUOTAS_TYPES_MAP,
} from 'config/constants';

import { useStyles } from './styles';

const TYPE_ITEMS = [
  { id: QUOTAS_TYPES.activeSession, title: QUOTAS_TYPES_MAP[QUOTAS_TYPES.activeSession] },
  { id: QUOTAS_TYPES.activeTicket, title: QUOTAS_TYPES_MAP[QUOTAS_TYPES.activeTicket] },
];

export default function OneQuotaPage({ match }) {
  const styles = useStyles();
  const history = useHistory();
  const { params, path } = match || {};
  const { id: quotaId } = params || {};

  const [fields, errors, onChange, setErrors, updateState] = useFormFields({
    initValues: {
      name: '',
      type: '',
      targetType: '',
      targetId: '',
      limit: 0,
      filters: [], // [{ type: '', value: '' }]
    },
  });

  const isNewMode = !quotaId && path === PATH_PAGES.newQuota;

  const { data: quotaData, isLoading: isQuotaLoading, refetch } = useOneQuota({
    variables: { id: quotaId },
    enabled: !isNewMode,
  });

  useEffect(() => {
    if (isNewMode) {
      return;
    }

    if (!quotaData) {
      return;
    }

    updateState({
      ...quotaData,
      filters: quotaData.filters.map((item) => ({
        type: item.targetType,
        value: item.value,
      })),
    });
  }, [isNewMode, quotaData, updateState]);

  const setQuotaMutation = useSetQuotaMutation({
    onSuccess: ({ success, id }) => {
      if (success) {
        if (isNewMode) {
          history.replace(PATH_PAGES.oneQuota.replace(':id', id));
        } else {
          history.push(PATH_PAGES.allQuotas);
        }

        toast.success('Квота успешно сохранена');
      }
    },
  });
  const { isLoading: isSetQuotaLoading } = setQuotaMutation || {};

  const deleteQuotaMutation = useDeleteQuotaMutation({
    onSuccess: ({ success }) => {
      if (success) {
        history.replace(PATH_PAGES.allQuotas);

        toast.success('Квота успешно удалена');
      }
    },
  });
  const { isLoading: isDeleteQuotaLoading } = deleteQuotaMutation || {};

  const onChangeType = useCallback((e) => {
    const newType = e.target.value;

    updateState({
      type: newType,
      filters: [],
    });
  }, [updateState]);

  const isValid = fields.type && fields.limit !== '';

  const onSave = useCallback(() => {
    if (isNewMode) {
      setQuotaMutation.mutate({
        name: fields.name,
        type: fields.type,
        limit: Number(fields.limit),
        usage: fields.usage,
        filters: (fields.filters || [])
          .filter((item) => item.type && !!item.value)
          .map((item) => ({
            targetType: item.type,
            operationType: 1, // equals // TODO: add more operation types
            value: item.value,
          })),
      });
    } else {
      setQuotaMutation.mutate({
        id: quotaId,
        name: fields.name,
        type: fields.type,
        limit: Number(fields.limit),
        usage: fields.usage,
        filters: (fields.filters || [])
          .filter((item) => item.type && !!item.value)
          .map((item) => ({
            targetType: item.type,
            operationType: 1, // equals // TODO: add more operation types
            value: item.value,
          })),
      });
    }
  }, [fields, isNewMode, quotaId, setQuotaMutation]);

  if (!isNewMode && isQuotaLoading) {
    return (
      <CircularIndeterminate style={{ minHeight: 600 }} />
    );
  }

  return (
    <Container className={styles.container} maxWidth="sm">
      <HeaderWithBackBtn
        title={isNewMode ? 'Добавление квоты' : 'Квота'}
      />
      <Grid container spacing={2} style={{ marginTop: 16 }}>
        <Grid item xs={12} md={8}>
          <ItemSelectors
            required
            classNameForm={styles.selectorsForm}
            classNameLabel={styles.selectorsLabel}
            className={styles.selector}
            items={TYPE_ITEMS}
            label="Квота"
            name="type"
            onChange={onChangeType}
            currentValue={fields.type}
          />
        </Grid>
        <Grid item xs={12} md={8}>
          <CustomTextInput
            required
            className={styles.inputItem}
            label="Лимит"
            name="limit"
            onChange={onChange}
            value={fields.limit}
            inputProps={{
              type: 'number',
              min: 0,
            }}
          />
        </Grid>
        {fields.type && (
          <>
            {(QUOTAS_TYPE_EXTRA_TARGETS[fields.type] || []).length > 0 && (
              <Grid item xs={12}>
                {(fields.filters || []).length > 0 && (
                  <Typography>Фильтры:</Typography>
                )}
                <Grid container spacing={2}>
                  {(fields.filters || []).map(({ type: filterType, value: filterValue }, index) => (
                    <>
                      <Grid key={`${fields.type}-${index}`} item xs={11}>
                        <QuotaTargetSelectors
                          targetIdValue={filterValue}
                          targetTypeValue={filterType}
                          onChangeTypeValue={(newType) => {
                            updateState({
                              filters: fields.filters.map((f, i) => (i === index ? { ...f, type: newType } : f)),
                            });
                          }}
                          onChangeIdValue={(newValue) => {
                            updateState({
                              filters: fields.filters.map((f, i) => (i === index ? { ...f, value: newValue } : f)),
                            });
                          }}
                          onlyTypes={QUOTAS_TYPE_EXTRA_TARGETS[fields.type]}
                        />
                      </Grid>
                      <Grid item xs={1}>
                        <IconButton
                          onClick={(e) => {
                            e.preventDefault();
                            updateState({
                              filters: fields.filters.filter((f, i) => (i !== index)),
                            });
                          }}
                        >
                          <DeleteIcon />
                        </IconButton>
                      </Grid>
                    </>
                  ))}
                  <CustomLink
                    style={{ marginLeft: '8px' }}
                    onClick={(e) => {
                      e.preventDefault();
                      updateState({ filters: [...fields.filters, { type: '', value: '' }] });
                    }}
                  >
                    Добавить фильтр
                  </CustomLink>
                </Grid>
              </Grid>
            )}
          </>
        )}
        <Grid item xs={12} md={8}>
          <CustomTextInput
            className={styles.inputItem}
            style={{ margin: 0 }}
            name="name"
            label="Название"
            value={fields.name}
            onChange={onChange}
          />
        </Grid>
        <Grid item xs={12} style={{ marginTop: 16 }}>
          <Grid container spacing={2}>
            <Grid item>
              <CustomBtn
                onClick={onSave}
                btnType="primaryBlue"
                disabled={!isValid || isSetQuotaLoading}
              >
                Сохранить
                {isSetQuotaLoading && (
                  <CircularProgress
                    style={{ marginLeft: 5 }}
                    size={20}
                    color="inherit"
                  />
                )}
              </CustomBtn>
            </Grid>
            {!isNewMode && (
              <Grid item>
                <CustomBtn
                  onClick={() => { deleteQuotaMutation.mutate({ id: quotaId }); }}
                  btnType="primaryRed"
                  disabled={isDeleteQuotaLoading}
                >
                  Удалить
                  {isDeleteQuotaLoading && (
                    <CircularProgress
                      style={{ marginLeft: 5 }}
                      size={20}
                      color="inherit"
                    />
                  )}
                </CustomBtn>
              </Grid>
            )}
          </Grid>
        </Grid>
      </Grid>
    </Container>
  );
}
