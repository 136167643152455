import React, { useCallback } from 'react';
import { CircularProgress, Popover } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import { bindPopover } from 'material-ui-popup-state';
import { usePopupState } from 'material-ui-popup-state/hooks';

import CustomBtn from '@parkly/shared/components/atoms/CustomBtn';

import { useStyles } from './styles';

export default function OpenGatesPopover({
  onOpen = () => {},
  gates,
  refId,
  direction,
  isLoading = false,
  disabled = false,
}) {
  const styles = useStyles();
  const popupState = usePopupState({
    variant: 'popover',
    popupId: `openGatesPopover-${refId}`,
  });
  const { isOpen } = popupState || {};

  const handleOnOpen = useCallback((id) => {
    if (isLoading) {
      return;
    }

    onOpen(id);
    popupState.close();
  }, [isLoading, onOpen, popupState]);

  const handleOnClick = useCallback((e) => {
    if (isLoading) {
      return;
    }

    if ((gates || []).length === 1) {
      const [{ id }] = gates;

      onOpen(id);
      return;
    }

    popupState.open(e);
  }, [isLoading, gates, popupState, onOpen]);

  let btnComponent = null;

  if (direction === 'enter') {
    btnComponent = (
      <CustomBtn
        onClick={handleOnClick}
        btnType="primaryGreen"
        startIcon={<ArrowForwardIcon htmlColor="white" />}
        disabled={isLoading || disabled}
      >
        Вьехать
        {isLoading && <CircularProgress size={20} />}
      </CustomBtn>
    );
  }

  if (direction === 'exit') {
    btnComponent = (
      <CustomBtn
        onClick={handleOnClick}
        btnType="primaryRed"
        startIcon={<ArrowBackIcon htmlColor="white" />}
        disabled={isLoading || disabled}
      >
        Выехать
        {isLoading && <CircularProgress size={20} />}
      </CustomBtn>
    );
  }

  return (
    <>
      {btnComponent}
      {isOpen && (
        <Popover
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'left',
          }}
          {...bindPopover(popupState)}
        >
          <div style={{ padding: 16, maxWidth: '90vw' }}>
            <Grid container spacing={1}>
              {(gates || []).map(({ id, name }) => (
                <Grid item key={id}>
                  <CustomBtn onClick={() => handleOnOpen(id)}>
                    {name}
                  </CustomBtn>
                </Grid>
              ))}
            </Grid>
          </div>
        </Popover>
      )}
    </>
  );
}
