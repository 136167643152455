import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
  paper: {

  },
  modalBody: {

  },
  container: {

  },
  input: {
    margin: 0,
  },
  datePicker: {
    width: '100%',

    '& input': {
      backgroundColor: '#F6F7FB',
    },

    '& input:focus': {

    },

    '& input:placeholder': {
      color: theme.palette.greyC.darkGrey,
    },
  },
  saveBtn: {
    width: '100%',
    height: 48,
  },

}));

export default {
  useStyles,
};
