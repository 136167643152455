import identity from 'lodash/identity';
import pickBy from 'lodash/pickBy';
import moment from 'moment-timezone';

import { callAPI } from '@parkly/shared/api';

import { PASS_REQ_TYPES } from 'config/constants';
import { parseReqType, toReqType } from 'helpers/reqValues';

async function validGetPassesSettings({
  res,
}) {
  const { data } = res || {};
  const curData = (data || {}).data || [];
  const curMeta = (data || {}).meta || {};

  const {
    current_page,
    from,
    last_page,
    path,
    per_page,
    to,
    total,
  } = curMeta || {};

  const passesMeta = {
    currentPage: current_page,
    from,
    lastPage: last_page,
    path,
    perPage: per_page,
    to,
    total,
  };

  const passesData = curData.map((item) => {
    const {
      id,
      name,
      intervalValue,
      intervalMode,
      tariffPlanId,
      tariffPlanName,
      status,
      paymentMethod,
    } = item;

    return {
      id,
      name,
      intervalValue,
      intervalMode,
      tariffPlanId,
      tariffPlanName,
      status,
      paymentMethod,
    };
  });

  const result = {
    passesData,
    passesMeta,
  };

  return result;
}

export async function getPassesSettings({
  facilityId,
  page,
  perPage,
}) {
  const query = new URLSearchParams(pickBy({
    page,
    perPage,
  }, identity));

  const queryParams = query.toString();

  const endpoint = `facilities/${facilityId}/pass-types?${queryParams}`;

  return callAPI(endpoint)
    .then((res) => validGetPassesSettings({
      res,
    }));
}

async function validGetPassSettings({ res }) {
  const { data } = res || {};
  const resultData = (data || {}).data || {};

  const {
    id,
    name,
    description,
    status,
    tariffPlanId,
    accessSchemaId,
    intervalValue,
    intervalMode,
    isShowingAtSite,
    isSingle,
    isIgnoreSessions,
    isIgnoreSchedule,
    isBookPlace,
    isFixedPlace,
    placePurpose,
    bookPlaceType,
    holdValue,
    holdMode,
    placeHoldValue,
    placeHoldMode,
    notifyValue,
    notifyMode,
    requiredData,
    places,
    maxDaysToStart,
    maxStartRenewals,
    paymentMethod,
  } = resultData;

  const { need: needPhone, required: requiredPhone } = parseReqType(requiredData, PASS_REQ_TYPES.phone);
  const { need: needPlateNumber, required: requiredPlateNumber } = parseReqType(requiredData, PASS_REQ_TYPES.plateNumber);
  const { need: needFio, required: requiredFio } = parseReqType(requiredData, PASS_REQ_TYPES.fio);
  const { need: needCarModel, required: requiredCarModel } = parseReqType(requiredData, PASS_REQ_TYPES.carModel);
  const { need: needStsNumber, required: requiredStsNumber } = parseReqType(requiredData, PASS_REQ_TYPES.stsNumber);
  const { need: needIdNumber, required: requiredIdNumber } = parseReqType(requiredData, PASS_REQ_TYPES.idNumber);
  const { need: needRFID, required: requiredRFID } = parseReqType(requiredData, PASS_REQ_TYPES.rfid);
  const { need: needBarcode, required: requiredBarcode } = parseReqType(requiredData, PASS_REQ_TYPES.barcode);
  const { need: needPinCode, required: requiredPinCode } = parseReqType(requiredData, PASS_REQ_TYPES.pinCode);
  const { need: needAdditionalPhone } = parseReqType(requiredData, PASS_REQ_TYPES.additionalPhone);
  const { need: needAdditionalPlateNumber } = parseReqType(requiredData, PASS_REQ_TYPES.additionalPlateNumber);

  return {
    id,
    name,
    description,
    status,
    tariffPlanId,
    accessSchemaId,
    intervalValue,
    intervalMode,
    isShowingAtSite,
    isSingle,
    isIgnoreSessions,
    isIgnoreSchedule,
    isBookPlace,
    isFixedPlace,
    placePurpose,
    bookPlaceType,
    maxDaysToStart,
    maxStartRenewals,
    places,
    holdValue,
    holdMode,
    placeHoldValue,
    placeHoldMode,
    notifyValue,
    notifyMode,
    paymentMethod,
    needPhone,
    requiredPhone,
    optionalPhone: !requiredPhone,
    needFio,
    requiredFio,
    optionalFio: !requiredFio,
    needPlateNumber,
    requiredPlateNumber,
    optionalPlateNumber: !requiredPlateNumber,
    needCarModel,
    requiredCarModel,
    optionalCarModel: !requiredCarModel,
    needStsNumber,
    requiredStsNumber,
    optionalStsNumber: !requiredStsNumber,
    needIdNumber,
    requiredIdNumber,
    optionalIdNumber: !requiredIdNumber,
    needRFID,
    requiredRFID,
    optionalRFID: !requiredRFID,
    needBarcode,
    requiredBarcode,
    optionalBarcode: !requiredBarcode,
    needPinCode,
    requiredPinCode,
    optionalPinCode: !requiredPinCode,
    needAdditionalPhone,
    needAdditionalPlateNumber,
  };
}

export async function getPassSetting({ facilityId, id }) {
  return callAPI(`facilities/${facilityId}/pass-types/${id}`)
    .then((res) => validGetPassSettings({
      res,
    }));
}

async function validGetPassSettingPlaces({ res }) {
  const { data } = res || {};
  const resultData = (data || {}).data || {};

  return resultData;
}

export async function getPassSettingPlaces({ facilityId, id, personalAccountId }) {
  const query = new URLSearchParams(pickBy({
    personalAccountId,
  }, identity));

  const queryParams = query.toString();

  return callAPI(`facilities/${facilityId}/pass-types/active/${id}/places?${queryParams}`)
    .then((res) => validGetPassSettingPlaces({
      res,
    }));
}

async function validGetActivePassTypes({
  res,
}) {
  const { data: resData } = res || {};
  const { data } = resData || {};

  const passesData = data.map((item) => {
    const {
      id,
      name,
      places,
      startTime,
      finishTime,
      requiredData,
      duration,
      price,
      isBookPlace,
      placePurpose,
      boolPlaceType,
      tariffPlanName,
      intervalMode,
      intervalValue,
      paymentMethod,
    } = item;

    const { need: needPhone, required: requiredPhone } = parseReqType(requiredData, PASS_REQ_TYPES.phone);
    const { need: needPlateNumber, required: requiredPlateNumber } = parseReqType(requiredData, PASS_REQ_TYPES.plateNumber);
    const { need: needFio, required: requiredFio } = parseReqType(requiredData, PASS_REQ_TYPES.fio);
    const { need: needCarModel, required: requiredCarModel } = parseReqType(requiredData, PASS_REQ_TYPES.carModel);
    const { need: needStsNumber, required: requiredStsNumber } = parseReqType(requiredData, PASS_REQ_TYPES.stsNumber);
    const { need: needIdNumber, required: requiredIdNumber } = parseReqType(requiredData, PASS_REQ_TYPES.idNumber);
    const { need: needRFID, required: requiredRFID } = parseReqType(requiredData, PASS_REQ_TYPES.rfid);
    const { need: needBarcode, required: requiredBarcode } = parseReqType(requiredData, PASS_REQ_TYPES.barcode);
    const { need: needPinCode, required: requiredPinCode } = parseReqType(requiredData, PASS_REQ_TYPES.pinCode);
    const { need: needAdditionalPhone } = parseReqType(requiredData, PASS_REQ_TYPES.additionalPhone);
    const { need: needAdditionalPlateNumber } = parseReqType(requiredData, PASS_REQ_TYPES.additionalPlateNumber);

    const {
      phone,
      fio,
      plateNumber,
      carModel,
      stsNumber,
      idNumber,
      rfid,
      barcode,
      pinCode,
      additionalPhone,
      additionalPlateNumber,
    } = requiredData || {};

    return {
      id,
      name,
      places,
      duration,
      startTime,
      finishTime,
      price,
      isBookPlace,
      placePurpose,
      boolPlaceType,
      tariffPlanName,
      intervalMode,
      intervalValue,
      paymentMethod,
      reqData: {
        phone,
        fio,
        plateNumber,
        carModel,
        stsNumber,
        idNumber,
        rfid,
        barcode,
        pinCode,
        additionalPhone,
        additionalPlateNumber,
      },
      needPhone,
      requiredPhone,
      optionalPhone: !requiredPhone,
      needFio,
      requiredFio,
      optionalFio: !requiredFio,
      needPlateNumber,
      requiredPlateNumber,
      optionalPlateNumber: !requiredPlateNumber,
      needCarModel,
      requiredCarModel,
      optionalCarModel: !requiredCarModel,
      needStsNumber,
      requiredStsNumber,
      optionalStsNumber: !requiredStsNumber,
      needIdNumber,
      requiredIdNumber,
      optionalIdNumber: !requiredIdNumber,
      needRFID,
      requiredRFID,
      optionalRFID: !requiredRFID,
      needBarcode,
      requiredBarcode,
      optionalBarcode: !requiredBarcode,
      needPinCode,
      requiredPinCode,
      optionalPinCode: !requiredPinCode,
      needAdditionalPhone,
      needAdditionalPlateNumber,
    };
  });

  return passesData;
}

export async function getActivePassSettings({
  facilityId,
  simple,
}) {
  const query = new URLSearchParams(pickBy({
    simple: simple ? 1 : undefined,
  }, identity));

  const queryParams = query.toString();

  return callAPI(`facilities/${facilityId}/pass-types/active?${queryParams}`)
    .then((res) => validGetActivePassTypes({
      res,
    }));
}

function validCreate(result) {
  const { res } = result || {};
  const { data } = res || {};

  const {
    id,
    success,
  } = data;

  return {
    id,
    success,
  };
}

export async function setPassSettings({
  facilityId,
  id,
  name,
  description,
  tariffPlanId,
  accessSchemaId,
  intervalValue,
  intervalMode,
  isShowingAtSite,
  isSingle,
  isIgnoreSessions,
  isBookPlace,
  isFixedPlace,
  placePurpose,
  bookPlaceType,
  holdValue,
  holdMode,
  placeHoldValue,
  placeHoldMode,
  notifyValue,
  notifyMode,
  maxDaysToStart,
  maxStartRenewals,
  paymentMethod,
  needPhone,
  requiredPhone,
  needFio,
  requiredFio,
  needPlateNumber,
  requiredPlateNumber,
  needCarModel,
  requiredCarModel,
  needStsNumber,
  requiredStsNumber,
  needIdNumber,
  requiredIdNumber,
  needRFID,
  requiredRFID,
  needBarcode,
  requiredBarcode,
  needPinCode,
  requiredPinCode,
  needAdditionalPhone,
  needAdditionalPlateNumber,
  status,
  places,
}) {
  return callAPI(
    `facilities/${facilityId}/pass-types`,
    'POST',
    {
      id: id || null,
      name,
      description,
      status,
      tariffPlanId,
      accessSchemaId,
      intervalValue,
      intervalMode,
      isShowingAtSite,
      isSingle,
      isIgnoreSessions,
      isBookPlace,
      isFixedPlace,
      placePurpose,
      bookPlaceType,
      holdValue: holdValue || 0,
      holdMode,
      placeHoldValue: placeHoldValue || 0,
      placeHoldMode,
      notifyValue: notifyValue || 0,
      notifyMode,
      maxDaysToStart: maxDaysToStart || 0,
      places,
      maxStartRenewals: maxStartRenewals || 1,
      paymentMethod,
      requiredData: {
        phone: toReqType(needPhone, requiredPhone),
        fio: toReqType(needFio, requiredFio),
        plateNumber: toReqType(needPlateNumber, requiredPlateNumber),
        carModel: toReqType(needCarModel, requiredCarModel),
        stsNumber: toReqType(needStsNumber, requiredStsNumber),
        idNumber: toReqType(needIdNumber, requiredIdNumber),
        rfid: toReqType(needRFID, requiredRFID),
        barcode: toReqType(needBarcode, requiredBarcode),
        pinCode: toReqType(needPinCode, requiredPinCode),
        additionalPhone: toReqType(needAdditionalPhone, false),
        additionalPlateNumber: toReqType(needAdditionalPlateNumber, false),
      },
    },
  )
    .then((res) => validCreate({
      res,
    }));
}

async function validGetPasses({
  res,
}) {
  const { data } = res || {};
  const curData = (data || {}).data || [];
  const curMeta = (data || {}).meta || {};

  const {
    current_page,
    from,
    last_page,
    path,
    per_page,
    to,
    total,
  } = curMeta || {};

  const passesMeta = {
    currentPage: current_page,
    from,
    lastPage: last_page,
    path,
    perPage: per_page,
    to,
    total,
  };

  const passesData = curData.map((item) => {
    const {
      id,
      number,
      facilityTitle,
      facilityId,
      passTypeName,
      passTypeId,
      passTypePaymentMethod,
      customerName,
      customerId,
      finishTime,
      startTime,
      placeName,
      status,
      isOverdue,
      requiredData,
      price,
      canBeRenewal,
      canBeCanceled,
      personalAccountId,
      personalAccountName,
    } = item;

    return {
      id,
      number,
      facilityTitle,
      facilityId,
      passTypeName,
      passTypeId,
      passTypePaymentMethod,
      customerName,
      customerId,
      finishTime,
      startTime,
      placeName,
      status,
      isOverdue,
      requiredData,
      price,
      canBeRenewal,
      canBeCanceled,
      personalAccountId,
      personalAccountName,
    };
  });

  const result = {
    passesData,
    passesMeta,
  };

  return result;
}

export async function getPasses({
  facilityId,
  type,
  status,
  passType,
  personalAccount,
  search,
  place,
  dateFrom,
  dateTo,
  page,
  perPage,
  sortBy,
  sortDirection,
}) {
  const query = new URLSearchParams(pickBy({
    page,
    perPage,
    type,
    status: status.join(','),
    personalAccount: personalAccount.join(','),
    dateFrom: dateFrom && moment(dateFrom).utc().toISOString(),
    dateTo: dateTo && moment(dateTo).utc().toISOString(),
    passType: passType === 'unselect' ? undefined : passType,
    search,
    place,
    sortBy,
    sortDirection,
  }, identity));

  const queryParams = query.toString();

  const endpoint = `facilities/${facilityId}/pass?${queryParams}`;

  return callAPI(endpoint)
    .then((res) => validGetPasses({
      res,
    }));
}

async function validGetPass({ res }) {
  const { data } = res || {};
  const resultData = (data || {}).data || {};

  const {
    id,
    number,
    facilityId,
    facility,
    customerName,
    customerId,
    status,
    price,
    passTypeId,
    passType,
    placeId,
    areaId,
    startTime,
    finishTime,
    requiredData,
    url,
    canBeRenewal,
    canBeCanceled,
    personalAccountId,
    personalAccountName,
  } = resultData;

  const {
    requiredData: passTypeRequiredData,
  } = passType || {};

  const { need: needPhone, required: requiredPhone } = parseReqType(passTypeRequiredData, PASS_REQ_TYPES.phone);
  const { need: needPlateNumber, required: requiredPlateNumber } = parseReqType(passTypeRequiredData, PASS_REQ_TYPES.plateNumber);
  const { need: needFio, required: requiredFio } = parseReqType(passTypeRequiredData, PASS_REQ_TYPES.fio);
  const { need: needCarModel, required: requiredCarModel } = parseReqType(passTypeRequiredData, PASS_REQ_TYPES.carModel);
  const { need: needStsNumber, required: requiredStsNumber } = parseReqType(passTypeRequiredData, PASS_REQ_TYPES.stsNumber);
  const { need: needIdNumber, required: requiredIdNumber } = parseReqType(passTypeRequiredData, PASS_REQ_TYPES.idNumber);
  const { need: needRFID, required: requiredRFID } = parseReqType(passTypeRequiredData, PASS_REQ_TYPES.rfid);
  const { need: needBarcode, required: requiredBarcode } = parseReqType(passTypeRequiredData, PASS_REQ_TYPES.barcode);
  const { need: needPinCode, required: requiredPinCode } = parseReqType(passTypeRequiredData, PASS_REQ_TYPES.pinCode);
  const { need: needAdditionalPhone } = parseReqType(passTypeRequiredData, PASS_REQ_TYPES.additionalPhone);
  const { need: needAdditionalPlateNumber } = parseReqType(passTypeRequiredData, PASS_REQ_TYPES.additionalPlateNumber);

  return {
    id,
    number,
    facilityId,
    facility,
    customerName,
    customerId,
    price,
    placeId,
    areaId,
    status,
    personalAccountId,
    personalAccountName,
    passTypeId,
    passType: {
      ...(passType || {}),
      needPhone,
      requiredPhone,
      needFio,
      requiredFio,
      needPlateNumber,
      requiredPlateNumber,
      needCarModel,
      requiredCarModel,
      needStsNumber,
      requiredStsNumber,
      needIdNumber,
      requiredIdNumber,
      needRFID,
      requiredRFID,
      needBarcode,
      requiredBarcode,
      needPinCode,
      requiredPinCode,
      needAdditionalPhone,
      needAdditionalPlateNumber,
    },
    startTime,
    finishTime,
    requiredData,
    url,
    canBeRenewal,
    canBeCanceled,
  };
}

export async function getPass({ facilityId, id }) {
  return callAPI(`facilities/${facilityId}/pass/${id}`)
    .then((res) => validGetPass({
      res,
    }));
}

async function validGetPassInfo({ res }) {
  const { data } = res || {};
  const resultData = (data || {}).data || {};

  const {
    id,
    number,
    customer,
    status,
    price,
    passType,
    place,
    startTime,
    finishTime,
    requiredData,
    url,
    sessions,
    payments,
    canBeRenewal,
    canBeCanceled,
    personalAccountId,
    personalAccountName,
  } = resultData;

  return {
    id,
    number,
    customer,
    price,
    place,
    status,
    passType,
    startTime,
    finishTime,
    requiredData,
    url,
    sessions,
    payments,
    canBeRenewal,
    canBeCanceled,
    personalAccountId,
    personalAccountName,
  };
}

export async function getPassInfo({ facilityId, id }) {
  return callAPI(`facilities/${facilityId}/pass/${id}/info`)
    .then((res) => validGetPassInfo({
      res,
    }));
}

export async function createPass({
  facilityId,
  customerId,
  status,
  passTypeId,
  placeId,
  personalAccountId,
  startTime,
  finishTime,
  reqValues,
}) {
  return callAPI(
    `facilities/${facilityId}/pass`,
    'POST',
    {
      status,
      customerId: customerId || null,
      passTypeId,
      placeId: placeId || null,
      startTime,
      finishTime,
      requiredData: reqValues,
      personalAccountId,
    },
  )
    .then((res) => validCreate({
      res,
    }));
}

export async function updatePass({
  facilityId,
  id,
  customerId,
  status,
  passTypeId,
  personalAccountId,
  placeId,
  startTime,
  finishTime,
  reqValues,
}) {
  return callAPI(
    `facilities/${facilityId}/pass`,
    'PUT',
    {
      id,
      status,
      customerId,
      passTypeId,
      placeId: placeId || null,
      startTime,
      finishTime,
      personalAccountId,
      requiredData: reqValues,
    },
  )
    .then((res) => validCreate({
      res,
    }));
}

export async function addPassPayment({
  facilityId,
  id,
  amount,
}) {
  return callAPI(
    `facilities/${facilityId}/pass/${id}/payment`,
    'POST',
    {
      amount,
    },
  )
    .then((res) => validCreate({
      res,
    }));
}

export async function cancelPass({
  facilityId,
  id,
  withPayment = true,
}) {
  return callAPI(
    `facilities/${facilityId}/pass/${id}/cancel`,
    'POST',
    {
      withPayment,
    },
  )
    .then((res) => validCreate({
      res,
    }));
}

export async function stopSessionPass({
  facilityId,
  id,
}) {
  return callAPI(
    `facilities/${facilityId}/pass/${id}/finish-active-session`,
    'POST',
  )
    .then((res) => validCreate({
      res,
    }));
}

async function validRenewalPassInfo({ res }) {
  const { data } = res || {};
  const resultData = (data || {}).data || {};

  const {
    from,
    to,
    price,
  } = resultData || {};

  return {
    from,
    to,
    price,
  };
}

export async function renewalPassInitInfo({
  facilityId,
  id,
}) {
  return callAPI(
    `facilities/${facilityId}/pass/${id}/renewal/init`,
    'POST',
  )
    .then((res) => validRenewalPassInfo({
      res,
    }));
}

export async function renewalPassPriceInfo({
  facilityId,
  id,
  to,
}) {
  return callAPI(
    `facilities/${facilityId}/pass/${id}/renewal/price`,
    'POST',
    {
      to,
    },
  )
    .then((res) => validRenewalPassInfo({
      res,
    }));
}

async function validRenewalRenew({ res }) {
  const { data } = res || {};
  const resultData = (data || {}).data || {};

  const {
    from,
    to,
    price,
  } = resultData || {};

  return {
    from,
    to,
    price,
  };
}

export async function renewalPassRenew({
  facilityId,
  id,
  to,
  price,
  isPaid,
}) {
  return callAPI(
    `facilities/${facilityId}/pass/${id}/renewal/renew`,
    'POST',
    {
      to,
      price,
      isPaid,
    },
  )
    .then((res) => validCreate({
      res,
    }));
}

export async function sendPassLinkToCustomer({
  facilityId,
  id,
}) {
  return callAPI(
    `facilities/${facilityId}/pass/${id}/notify-link`,
    'POST',
  )
    .then((res) => validCreate({
      res,
    }));
}

export async function addPassPersonalAccountPayment({ id, facilityId, personalAccountId }) {
  return callAPI(
    `facilities/${facilityId}/pass/${id}/personal-account/payment`,
    'POST',
    {
      personalAccountId,
    },
  )
    .then((res) => validCreate({ res }));
}
