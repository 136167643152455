import React from 'react';

import { useActivePassTypePlaces } from 'api/query/passes';
import PlaceAccordionSelect from 'components/molecules/PlaceAccordionSelect';

function PassPlaceSelect({
  passTypeId,
  facilityId,
  personalAccountId,

  initPlaceId,
  initAreaId,

  onChange = () => {},
  selTitle = '',

  disabled = false,
  enableReserved = false,
}) {
  const { data: areas, isLoading } = useActivePassTypePlaces({
    variables: { facilityId, id: passTypeId, personalAccountId },
  });

  return (
    <PlaceAccordionSelect
      onChange={onChange}
      selTitle={selTitle}
      initPlaceId={initPlaceId}
      initAreaId={initAreaId}
      disabled={disabled}
      areas={areas}
      isLoading={isLoading}
      enableReserved={enableReserved}
    />
  );
}

export default PassPlaceSelect;
