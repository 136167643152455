import React, { useCallback, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { CircularProgress, Grid } from '@material-ui/core';
import Container from '@material-ui/core/Container';
import IconButton from '@material-ui/core/IconButton';
import InputAdornment from '@material-ui/core/InputAdornment';
import Typography from '@material-ui/core/Typography';
import CancelIcon from '@material-ui/icons/Cancel';
import CloseIcon from '@material-ui/icons/Close';
import PlayArrowIcon from '@material-ui/icons/PlayArrow';
import clone from 'lodash/clone';
import moment from 'moment-timezone';

import CircularIndeterminate from '@parkly/shared/components/atoms/CircularIndeterminate';
import CustomBtn from '@parkly/shared/components/atoms/CustomBtn';
import CustomTextInput from '@parkly/shared/components/atoms/CustomTextInput';
import HeaderWithBackBtn from '@parkly/shared/components/molecules/HeaderWithBackBtn';
import ItemSelectors from '@parkly/shared/components/molecules/ItemSelectors';
import { MAX_PHONE_NUMBER_LENGTH } from '@parkly/shared/config/constants';
import {
  cleanPhone,
  formatPhoneNumber,
  formatPhoneNumberInput,
  getApiErrorMsg,
  useFormFields,
} from '@parkly/shared/helpers';
import useConfirm from '@parkly/shared/helpers/confirm';

import { usePersonalAccountsForCharge } from 'api/query/personalAccounts';
import {
  useActivateTicketMutation,
  useCancelTicketMutation,
  useTicket,
  useUpdateTicketMutation,
} from 'api/query/tickets';
import DateTimeRangePicker from 'components/molecules/DateTimeRangePicker';
import TicketPlaceSelect from 'components/organisms/TicketPlaceSelect';
import {
  MODULES_KEYS, PASS_BOOK_PLACE_TYPES, PASS_PAYMENT_METHODS,
  PASS_REQ_TYPE_NEED,
  PASS_REQ_TYPES,
  PASS_REQ_TYPES_MAP,
  PASSES_STATUSES,
  PATH_PAGES, PLACE_PURPOSES,
  RIGHT_KEYS,
} from 'config/constants';
import { useCheckFacilityRights, useCommonRights, useHasFacilityModule } from 'helpers/hooks';
import { useCurrentFacilityMatch } from 'helpers/routerHooks';
import { getTicketErrorDates } from 'helpers/validation';

import { useStyles } from './styles';

function formatValue(name, value) {
  if (name === 'phone') {
    return formatPhoneNumberInput(value);
  }

  return value;
}

function OneTicketPage({
  match,
}) {
  const { t } = useTranslation();
  const styles = useStyles();
  const confirm = useConfirm();
  const history = useHistory();
  useCurrentFacilityMatch(PATH_PAGES.tickets);
  const { params } = match || {};
  const {
    id: ticketId,
    facilityId,
  } = params || {};

  const hasPersonalAccountModule = useHasFacilityModule(facilityId, MODULES_KEYS.personalAccounts);

  const [fields, errors, onChange, setErrors, updateState] = useFormFields({
    initValues: {
      customer: '',
      customerId: '',
      passTypeId: '',
      placeId: '',
      startTime: null,
      finishTime: null,
      personalAccountId: '',
      status: PASSES_STATUSES.draft,
      reqValues: [],
      optionalReqValues: [],
      additionalReqValues: [],
    },
    formatValueFunc: formatValue,
  });

  const hasTicketUpdateRight = useCheckFacilityRights(RIGHT_KEYS.ticketUpdate, facilityId);
  const hasPersonalAccountChargeRight = useCommonRights(RIGHT_KEYS.personalAccountsCharge);

  const {
    data: currentTicket,
    isLoading,
    error: currentTicketError,
    refetch: ticketRefetch,
  } = useTicket({ variables: { facilityId, id: ticketId } });

  useEffect(() => {
    if (currentTicket) {
      const reqValues = [];
      const optionalReqValues = [];
      const additionalReqValues = [];

      Object.entries(currentTicket.passType.requiredData).forEach((entry) => {
        const [key, value] = entry;

        if (key.includes('additional')) {
          return;
        }

        const reqValue = currentTicket.requiredData.find(({ type }) => type === key);

        if (!reqValue) {
          return;
        }

        let newValue = '';
        if ([PASS_REQ_TYPES.phone, PASS_REQ_TYPES.additionalPhone].includes(key)) {
          newValue = formatPhoneNumber(reqValue.value);
        } else {
          newValue = reqValue.value;
        }

        if (value === PASS_REQ_TYPE_NEED.required) {
          reqValues.push({ ...reqValue, type: key, value: newValue });
        }

        if (value === PASS_REQ_TYPE_NEED.optional) {
          optionalReqValues.push({ ...reqValue, type: key, value: newValue });
        }
      });

      currentTicket.requiredData.forEach(({ type, value, ...other }) => {
        if (![PASS_REQ_TYPES.additionalPhone, PASS_REQ_TYPES.additionalPlateNumber].includes(type)) {
          return;
        }

        let newValue = '';
        if ([PASS_REQ_TYPES.phone, PASS_REQ_TYPES.additionalPhone].includes(type)) {
          newValue = formatPhoneNumber(value);
        } else {
          newValue = value;
        }

        additionalReqValues.push({ type, value: newValue, ...other });
      });

      updateState({
        ...currentTicket,
        reqValues,
        optionalReqValues,
        additionalReqValues,
        startTime: moment(currentTicket.startTime),
        finishTime: moment(currentTicket.finishTime),
      });
    }
  }, [facilityId, currentTicket, updateState]);

  const {
    status,
    url,
    placeId,
    areaId,
    passType,
    canBeCanceled,
  } = currentTicket || {};
  const { placePurpose, bookPlaceType } = passType || {};

  useEffect(() => {
    if (fields.personalAccountId && placePurpose === PLACE_PURPOSES.personalAccount) {
      updateState({
        placeId: '',
        placeTitle: '',
      });
    }
  }, [fields.personalAccountId, placePurpose, updateState]);

  const updateTicketMutation = useUpdateTicketMutation({
    onSuccess() {

    },
    onError(error) {
      if (error) {
        const { response } = error || {};
        const { data } = response || {};
        const backErrors = (data || {}).errors || {};

        setErrors({
          ...backErrors,
        });
      }
    },
  });

  const { isLoading: isSaveLoading } = updateTicketMutation || {};

  const onSubmit = (e) => {
    e.preventDefault();
    e.nativeEvent.preventDefault();

    if (!hasTicketUpdateRight) {
      return;
    }

    if (!fields.startTime || !fields.finishTime) {
      setErrors({
        startTime: true,
        finishTime: true,
      });

      return;
    }

    if (getTicketErrorDates({
      startTime: fields.startTime, finishTime: fields.finishTime, maxDaysToStart: passType.maxDaysToStart, maxDuration: passType.duration,
    })) {
      return;
    }

    updateTicketMutation.mutate({
      id: ticketId,
      facilityId,
      customerId: fields.customerId,
      ticketTypeId: fields.passTypeId,
      comment: fields.comment,
      placeId: fields.placeId || null,
      startTime: fields.startTime ? fields.startTime : null,
      finishTime: fields.finishTime ? fields.finishTime : null,
      status: fields.status,
      personalAccountId: fields.personalAccountId,
      reqValues: fields.reqValues
        .concat(fields.optionalReqValues)
        .concat(fields.additionalReqValues)
        .filter(({ value }) => !!value)
        .map(({ type, value, ...other }) => ({
          type,
          value: [PASS_REQ_TYPES.phone, PASS_REQ_TYPES.additionalPhone].includes(type) ? cleanPhone(value) : value,
          ...other,
        }))
        .filter(({ value, type }) => !([PASS_REQ_TYPES.phone, PASS_REQ_TYPES.additionalPhone].includes(type) && value === '7')),
    });
  };

  const activateTicketMutation = useActivateTicketMutation({
    onSuccess({ success }) {
      if (success) {
        ticketRefetch();
      }
    },
  });

  const { isLoading: isActivateLoading } = activateTicketMutation || {};

  const handleActivateTicketClick = useCallback(() => {
    confirm({
      title: 'Вы уверены, что хотите активировать пропуск?',
      agreeTitle: 'Активировать',
      disagreeTitle: 'Отменить',
    }).then(() => {
      activateTicketMutation.mutate({ facilityId, id: ticketId });
    }).catch(() => {});
  }, [activateTicketMutation, confirm, facilityId, ticketId]);

  const cancelTicketMutation = useCancelTicketMutation({
    onSuccess({ success }) {
      if (success) {
        ticketRefetch();
      }
    },
  });

  const { isLoading: isCancelLoading } = cancelTicketMutation || {};

  const handleCancelTicketClick = useCallback(() => {
    confirm({
      title: 'Вы уверены, что хотите отменить пропуск?',
      agreeTitle: 'Да, отменить',
      disagreeTitle: 'Нет',
    }).then(() => {
      cancelTicketMutation.mutate({ facilityId, id: ticketId });
    }).catch(() => {});
  }, [cancelTicketMutation, confirm, facilityId, ticketId]);

  const { data: personalAccountsData, isLoading: isPersonalAccountsLoading } = usePersonalAccountsForCharge({
    variables: {},
    enabled: hasPersonalAccountModule && hasPersonalAccountChargeRight,
  });

  const personalAccountItems = useMemo(() => (personalAccountsData || []).map(({ id, name, number }) => ({
    id,
    title: name || number,
  })), [personalAccountsData]);

  if (isLoading || (isPersonalAccountsLoading && hasPersonalAccountModule && hasPersonalAccountChargeRight)) {
    return (
      <CircularIndeterminate style={{ minHeight: 600 }} />
    );
  }

  if (currentTicketError) {
    const currentTicketErrorMsg = getApiErrorMsg(currentTicketError);
    return (
      <Container className={styles.container}>
        <HeaderWithBackBtn
          title="Пропуск"
        />
        <Typography>{currentTicketErrorMsg}</Typography>
      </Container>
    );
  }

  const updateAvailable = [PASSES_STATUSES.active, PASSES_STATUSES.draft, PASSES_STATUSES.awaitingPayment].includes(status)
    || !hasTicketUpdateRight;

  return (
    <Container className={styles.container} maxWidth="md">
      <HeaderWithBackBtn
        title="Пропуск"
      />
      <form noValidate className={styles.form} onSubmit={onSubmit}>
        <Grid container spacing={2}>
          <Grid item container spacing={2} xs={12}>
            <Grid item xs={12} md={7}>
              <CustomTextInput
                required
                className={styles.inputItem}
                value={fields.passType ? fields.passType.name : ''}
                disabled
                label="Тип пропуска"
              />
            </Grid>
            <Grid item xs={12} md={8}>
              <DateTimeRangePicker
                label="Период"
                required
                clearable
                error={!!errors.startTime || !!errors.finishTime || !!getTicketErrorDates({
                  startTime: fields.startTime, finishTime: fields.finishTime, maxDaysToStart: passType.maxDaysToStart, maxDuration: passType.duration,
                })}
                errorText={getTicketErrorDates({
                  startTime: fields.startTime, finishTime: fields.finishTime, maxDaysToStart: passType.maxDaysToStart, maxDuration: passType.duration,
                })}
                local={{
                  fromDate: 'Начало пропуска',
                  toDate: 'Конец пропуска',
                }}
                rangesType="ticket"
                // smartMode
                autoApply
                initStart={fields.startTime}
                initEnd={fields.finishTime}
                onChange={({ start, end }) => {
                  updateState({
                    startTime: start,
                    finishTime: end,
                  });
                  setErrors({
                    startTime: false,
                    finishTime: false,
                  });
                }}
              />
            </Grid>
            {hasPersonalAccountModule && passType.paymentMethod === PASS_PAYMENT_METHODS.personalAccount && (
              <Grid item xs={12} md={6}>
                <ItemSelectors
                  classNameForm={styles.selectorsForm}
                  classNameLabel={styles.selectorsLabel}
                  className={styles.selector}
                  disabled={!hasTicketUpdateRight}
                  label="Лицевой счет"
                  items={personalAccountItems}
                  error={!!errors.personalAccountId}
                  name="personalAccountId"
                  onChange={onChange}
                  currentValue={fields.personalAccountId}
                />
              </Grid>
            )}
            {fields.passType && fields.passType.isBookPlace && (
              <Grid item xs={12} md={10}>
                <TicketPlaceSelect
                  disabled={!updateAvailable}
                  facilityId={facilityId}
                  ticketTypeId={fields.passTypeId}
                  personalAccountId={fields.personalAccountId}
                  initPlaceId={placeId}
                  initAreaId={areaId}
                  selTitle={fields.placeTitle}
                  onChange={(id, title) => updateState({ placeId: id, placeTitle: title })}
                  enableReserved={bookPlaceType === PASS_BOOK_PLACE_TYPES.session}
                />
              </Grid>
            )}
            {/* <Grid item xs={12} md={6}> */}
            {/*  <CustomTextInput */}
            {/*    className={styles.inputItem} */}
            {/*    value={PASSES_STATUSES_MAP[fields.status]} */}
            {/*    name="status" */}
            {/*    disabled */}
            {/*    label="Статус" */}
            {/*  /> */}
            {/* </Grid> */}
            <Grid item xs={12}>
              <CustomTextInput
                className={styles.inputItem}
                error={!!errors.comment}
                name="comment"
                label="Комментарий"
                value={fields.comment}
                autoComplete="off"
                disabled={!hasTicketUpdateRight}
                onChange={onChange}
              />
            </Grid>
            <Grid item xs={12} md={7}>
              <CustomTextInput
                required
                className={styles.inputItem}
                value={fields.customerName || ''}
                name="customerName"
                error={!!errors.customerId}
                disabled
                label="Клиент"
              />
            </Grid>
            <Grid item xs={12}>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Typography>Обязательные данные:</Typography>
                </Grid>
                {fields && (fields.reqValues || []).map(({ value, type }, reqIndex) => (
                  <Grid key={`req_value_${reqIndex}`} item xs={12} sm={6} md={4}>
                    <CustomTextInput
                    // required={!type.includes('additional')}
                      className={styles.inputItem}
                      error={errors[`requiredData.${reqIndex}.value`] || errors[`requiredData.${type}`] || false}
                      label={PASS_REQ_TYPES_MAP[type]}
                      value={value || ''}
                      autoComplete="off"
                      disabled={!updateAvailable}
                      inputProps={{
                        maxLength: [PASS_REQ_TYPES.phone, PASS_REQ_TYPES.additionalPhone].includes(type)
                          ? MAX_PHONE_NUMBER_LENGTH : 250,
                      }}
                      InputProps={{
                        endAdornment: type.includes('additional') ? (
                          <InputAdornment position="end">
                            <IconButton
                              disabled={!updateAvailable}
                              size="small"
                              onClick={() => {
                                const newReqValues = clone(fields.reqValues);
                                newReqValues[reqIndex].value = '';
                                updateState({
                                  reqValues: newReqValues,
                                });
                              }}
                            >
                              <CloseIcon fontSize="small" />
                            </IconButton>
                          </InputAdornment>
                        ) : null,
                      }}
                      onChange={(e) => {
                        const { target } = e || {};
                        const {
                          value: newValue,
                        } = target || {};

                        const newReqValues = clone(fields.reqValues);

                        if ([PASS_REQ_TYPES.phone, PASS_REQ_TYPES.additionalPhone].includes(type)) {
                          newReqValues[reqIndex].value = formatPhoneNumberInput(newValue);
                        } else {
                          newReqValues[reqIndex].value = newValue;
                        }

                        updateState({
                          reqValues: newReqValues,
                        });
                        setErrors({
                          [`requiredData.${reqIndex}.value`]: false,
                          [`requiredData.${type}`]: false,
                        });
                      }}
                    />
                  </Grid>
                ))}
                <Grid item xs={12}>
                  <Typography>Дополнительные данные:</Typography>
                </Grid>
                {fields && (fields.optionalReqValues || []).map(({ value, type }, reqIndex) => (
                  <Grid key={`optional_req_value_${reqIndex}`} item xs={12} sm={6} md={4}>
                    <CustomTextInput
                      required={false}
                      className={styles.inputItem}
                      error={errors[`requiredData.${reqIndex}.value`] || errors[`requiredData.${type}`] || false}
                      label={PASS_REQ_TYPES_MAP[type]}
                      value={value || ''}
                      autoComplete="off"
                      disabled={!updateAvailable}
                      inputProps={{
                        maxLength: [PASS_REQ_TYPES.phone, PASS_REQ_TYPES.additionalPhone].includes(type)
                          ? MAX_PHONE_NUMBER_LENGTH : 250,
                      }}
                      InputProps={{
                        endAdornment: type.includes('additional') ? (
                          <InputAdornment position="end">
                            <IconButton
                              disabled={!updateAvailable}
                              size="small"
                              onClick={() => {
                                const newReqValues = clone(fields.optionalReqValues);
                                newReqValues.splice(reqIndex, 1);
                                updateState({
                                  optionalReqValues: newReqValues,
                                });
                              }}
                            >
                              <CloseIcon fontSize="small" />
                            </IconButton>
                          </InputAdornment>
                        ) : null,
                      }}
                      onChange={(e) => {
                        const { target } = e || {};
                        const {
                          value: newValue,
                        } = target || {};

                        const newReqValues = clone(fields.optionalReqValues);

                        if ([PASS_REQ_TYPES.phone, PASS_REQ_TYPES.additionalPhone].includes(type)) {
                          newReqValues[reqIndex].value = formatPhoneNumberInput(newValue);
                        } else {
                          newReqValues[reqIndex].value = newValue;
                        }

                        updateState({
                          optionalReqValues: newReqValues,
                        });
                        setErrors({
                          [`requiredData.${reqIndex}.value`]: false,
                          [`requiredData.${type}`]: false,
                        });
                      }}
                    />
                  </Grid>
                ))}
              </Grid>
            </Grid>
          </Grid>
          <Grid container className={styles.btnsContainer} spacing={2} item xs={12}>
            <Grid item>
              <CustomBtn
                type="submit"
                className={styles.btnSubmit}
                btnType="primaryBlue"
                disabled={!updateAvailable || isSaveLoading || isActivateLoading || isCancelLoading}
              >
                Сохранить
                {isSaveLoading && (
                  <CircularProgress
                    style={{ marginLeft: 5 }}
                    size={20}
                    color="inherit"
                  />
                )}
              </CustomBtn>
            </Grid>
            {hasTicketUpdateRight && status === PASSES_STATUSES.draft && (
              <Grid item>
                <CustomBtn
                  className={styles.btnSubmit}
                  btnType="primaryGreen"
                  onClick={handleActivateTicketClick}
                  disabled={isActivateLoading || isCancelLoading}
                >
                  <PlayArrowIcon style={{ marginRight: 5 }} fontSize="small" />
                  Активировать
                  {isActivateLoading && (
                    <CircularProgress
                      style={{ marginLeft: 5 }}
                      size={20}
                      color="inherit"
                    />
                  )}
                </CustomBtn>
              </Grid>
            )}
            {hasTicketUpdateRight && canBeCanceled && (
              <Grid item>
                <CustomBtn
                  className={styles.btnSubmit}
                  btnType="primaryRed"
                  onClick={handleCancelTicketClick}
                  disabled={isCancelLoading || isActivateLoading}
                >
                  <CancelIcon style={{ marginRight: 5 }} fontSize="small" />
                  Отменить
                  {isCancelLoading && (
                    <CircularProgress
                      style={{ marginLeft: 5 }}
                      size={20}
                      color="inherit"
                    />
                  )}
                </CustomBtn>
              </Grid>
            )}
          </Grid>
        </Grid>
      </form>
    </Container>
  );
}

export default OneTicketPage;
