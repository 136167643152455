import React, { useEffect, useMemo, useState } from 'react';
import { toast } from 'react-toastify';
import Grid from '@material-ui/core/Grid';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';

import CustomBtn from '@parkly/shared/components/atoms/CustomBtn';

import OpenGatesPopover from 'components/organisms/OpenGatesPopover';

import { useOpenGateTicketMutation } from '../../../api/query/tickets';

export default function PassGatesControl({
  facilityId,
  passId,
  gates,
  onSuccess = () => {},
  disabled = false,
}) {
  const openGateTicketMutation = useOpenGateTicketMutation({
    onSuccess({ success, message }) {
      if (success) {
        toast.success('Выполнено');
      } else {
        toast.error(message);
      }

      onSuccess();
    },
  });

  const { isLoading } = openGateTicketMutation || {};

  return (
    <Grid container spacing={1} wrap="nowrap">
      {(Object.entries(gates || {}).map(([direction, gatesData]) => (
        <Grid item>
          <OpenGatesPopover
            disabled={disabled}
            isLoading={isLoading}
            refId={passId}
            direction={direction}
            gates={gatesData}
            onOpen={(id) => { openGateTicketMutation.mutate({ gateId: id, facilityId, passId }); }}
          />
        </Grid>
      )))}
    </Grid>
  );
}
