import React from 'react';
import { connect } from 'react-redux';
import Grid from '@material-ui/core/Grid';
import Hidden from '@material-ui/core/Hidden';
import Pagination from '@material-ui/lab/Pagination';

import CircularIndeterminate from '@parkly/shared/components/atoms/CircularIndeterminate';

import GatewayEventsCards from 'components/templates/GatewayEventsCards';
import GatewayEventsTable from 'components/templates/GatewayEventsTable';

import { GATEWAY_EVENTS_TYPES } from '../../../../config/constants';

import { useStyles } from './styles';

function uniqueByGroupId(arr) {
  const seen = new Set();

  return arr.filter((item) => {
    if (item.type !== GATEWAY_EVENTS_TYPES.initDraft) {
      return true;
    }

    // Always include items with null groupId
    if (item.groupId === null || item.groupId === undefined) {
      return true;
    }
    // For non-null groupId, include the item if it hasn't been seen
    if (!seen.has(item.groupId)) {
      seen.add(item.groupId);
      return true;
    }
    // Otherwise, filter out the duplicate groupId
    return false;
  });
}

function GatewayEventsContent({
  facilityId,
  isLoading = false,
  gatewayEventsData = [],
  gatewayEventsMeta,
  showPagination = false,
  onPageChange = () => {},
  sorting,
  sortingSettingsChange,
  hideCustomer = false,
  hideControllers = true,
}) {
  const styles = useStyles();

  const {
    currentPage = 1,
    lastPage = 1,
  } = gatewayEventsMeta || {};

  const data = uniqueByGroupId(gatewayEventsData || []);

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        {isLoading && (
          <CircularIndeterminate style={{ minHeight: 600 }} />
        )}
        {!isLoading && (
          <>
            <Hidden implementation="js" smUp>
              <GatewayEventsCards
                facilityId={facilityId}
                hideCustomer={hideCustomer}
                gatewayEventsData={data}
              />
            </Hidden>
            <Hidden implementation="js" xsDown>
              <GatewayEventsTable
                facilityId={facilityId}
                hideCustomer={hideCustomer}
                gatewayEventsData={data}
                sorting={sorting}
                sortingSettingsChange={sortingSettingsChange}
                hideControllers={hideControllers}
              />
            </Hidden>
          </>
        )}
        {!isLoading && lastPage > 1 && showPagination && (
          <div className={styles.paginationContainer}>
            <Pagination
              className={styles.pagination}
              name="page"
              page={currentPage}
              onChange={onPageChange}
              count={lastPage}
              color="primary"
            />
          </div>
        )}
      </Grid>
    </Grid>
  );
}

function mapStateToProps(state) {
  return {};
}

const ConnectedGatewayEventsContent = connect(
  mapStateToProps,
  {},
)(GatewayEventsContent);

export default ConnectedGatewayEventsContent;
