import moment from 'moment-timezone';

import { getHumanizeDuration } from '@parkly/shared/helpers';

export function getTicketErrorDates({
  startTime, finishTime, maxDaysToStart, maxDuration,
}) {
  let startTimeError = '';
  let finishTimeError = '';
  if (startTime && maxDaysToStart) {
    const maxAllowedStart = moment().add(maxDaysToStart, 'days');
    if (startTime.isSameOrAfter(maxAllowedStart)) {
      startTimeError = `Дата начала пропуска не может быть позже чем ${maxAllowedStart.format('LL')}`;
    }
  }

  if (startTime && finishTime) {
    const maxAllowedFinish = moment(startTime).add(maxDuration, 'hours').add(1, 'second');
    if (finishTime.isSameOrAfter(maxAllowedFinish)) {
      finishTimeError = `Дата окончания пропуска не должна превышать ${getHumanizeDuration(maxDuration * 60 * 60 * 1000)} от даты начала пропуска`;
    }
  }

  return [startTimeError, finishTimeError].filter(Boolean).join('\n');
}
