import { createMutation, createQuery } from 'react-query-kit';

import {
  addSessionAccessTokens,
  changeBulkSessionStatus,
  changeSessionStatus,
  changeSessionTariffPlan,
  getSessionAccessTokens,
  getSessions,
  setSessionAccessTokens, startSessionForPass,
} from 'api/sessions';

export const useSessions = createQuery({
  primaryKey: 'sessions',
  queryFn: ({ queryKey: [_, params] }) => getSessions(params),
  retry: false,
});

export const useChangeSessionStatusMutation = createMutation({
  mutationFn: async ({
    id, facilityId, status, date,
  }) => changeSessionStatus({
    id, facilityId, status, date,
  }),
});

export const useChangeBulkSessionStatusMutation = createMutation({
  mutationFn: async ({ ids, facilityId, status }) => changeBulkSessionStatus({ ids, facilityId, status }),
});

export const useChangeSessionTariffPlanMutation = createMutation({
  mutationFn: async ({ id, facilityId, tariffPlanId }) => changeSessionTariffPlan({ id, facilityId, tariffPlanId }),
});

export const useStartSessionForPassMutation = createMutation({
  mutationFn: async (params) => startSessionForPass(params),
});

export const useSessionAccessTokens = createQuery({
  primaryKey: 'session-access-tokens',
  queryFn: ({ queryKey: [_, { id, facilityId }] }) => getSessionAccessTokens({ id, facilityId }),
  retry: false,
  keepPreviousData: false,
  staleTime: 0,
  cacheTime: 0,
});

export const useSetSessionAccessTokenMutation = createMutation({
  mutationFn: async ({
    id, facilityId, token, force,
  }) => setSessionAccessTokens({
    id, facilityId, token, force,
  }),
});

export const useAddSessionAccessTokenMutation = createMutation({
  mutationFn: async ({
    id, facilityId, type, token, force,
  }) => addSessionAccessTokens({
    id, facilityId, type, token, force,
  }),
});
