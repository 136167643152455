import React, { useCallback, useMemo } from 'react';
import { useHistory } from 'react-router-dom';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import moment from 'moment-timezone';
import {
  DelimitedNumericArrayParam, ObjectParam, StringParam, withDefault,
} from 'use-query-params';

import CustomBtn from '@parkly/shared/components/atoms/CustomBtn';
import CustomTextInput from '@parkly/shared/components/atoms/CustomTextInput';
import ItemSelectors from '@parkly/shared/components/molecules/ItemSelectors';
import MultiItemSelectors from '@parkly/shared/components/molecules/MultiItemSelectors';
import { toNativeTimeFormat } from '@parkly/shared/helpers';

import { useActiveTicketTypes, useTickets } from 'api/query/tickets';
import DateTimeRangePicker from 'components/molecules/DateTimeRangePicker';
import PersonalAccountSelect from 'components/molecules/PersonalAccountSelect';
import FiltersContainer from 'components/templates/containers/FiltersContainer';
import {
  MODULES_KEYS,
  PASSES_STATUSES, PASSES_STATUSES_MAP, PATH_PAGES, RIGHT_KEYS,
} from 'config/constants';
import {
  useCheckFacilityRights, useCommonRights, useDebounce, useHasFacilityModule,
} from 'helpers/hooks';
import {
  createEnumDelimitedNumericArrayParam, PageParam, withParams, withQueryParams,
} from 'helpers/queryParams';

import { useStyles } from './styles';
import TicketsContent from './TicketsContent';

const STATUS_ITEMS = [
  { id: PASSES_STATUSES.draft, title: PASSES_STATUSES_MAP[PASSES_STATUSES.draft] },
  // { id: PASSES_STATUSES.awaitingPayment, title: PASSES_STATUSES_MAP[PASSES_STATUSES.awaitingPayment] },
  { id: PASSES_STATUSES.active, title: PASSES_STATUSES_MAP[PASSES_STATUSES.active] },
  { id: PASSES_STATUSES.canceled, title: PASSES_STATUSES_MAP[PASSES_STATUSES.canceled] },
  { id: PASSES_STATUSES.finished, title: PASSES_STATUSES_MAP[PASSES_STATUSES.finished] },
];

function getInitParams(initParams) {
  return {
    sorting: {},
    // tabValue: 0,
    search: '',
    status: [],
    personalAccount: [],
    passType: 'unselect',
    dateFrom: null,
    dateTo: null,
    page: 1,
    ...initParams,
  };
}

function getNextSortStatus(status) {
  if (!status) {
    return 'sort';
  }

  if (status === 'sort') {
    return 'sortReverse';
  }

  return null;
}

// const TABS = [
//   { id: '', label: 'Все' },
//   { id: PASSES_TYPES.active, label: 'Активные' },
//   { id: PASSES_TYPES.overdue, label: 'Просроченные' },
//   { id: PASSES_TYPES.archive, label: 'Завершенные' },
// ];

function prepareParam({
  params,
}) {
  const {
    sorting,
    page,
    // tabValue,
    search: searchValue,
    place: placeValue,
    dateFrom,
    dateTo,
    status,
    personalAccount,
    passType,
    forceType,
  } = params || {};

  const sortRules = Object.keys(sorting).filter((currentId) => !!sorting[currentId]);
  const sortParam = sortRules.length > 0
    ? {
      sortBy: sortRules.join(','),
      sortDirection: sortRules.map((id) => {
        const rule = sorting[id];
        if (rule === 'sortReverse') {
          return 'desc';
        }

        return 'asc';
      }).join(','),
    }
    : {};

  // const currentTab = TABS[tabValue || 0] || {};
  // const type = forceType || currentTab.id;

  const search = (searchValue || '').trim();
  const place = (placeValue || '').trim();

  return {
    page,
    // type,
    // tabValue,
    search,
    place,
    status,
    passType,
    personalAccount,
    dateFrom,
    dateTo,
    sorting,
    ...sortParam,
  };
}

function Tickets({
  showControls = false,
  showPagination = false,
  facilityId,

  title,
  showTitleIfNotEmpty = false,
  showContentIfEmpty = true,

  // From HOC
  params,
  setParams,
}) {
  const styles = useStyles();
  const history = useHistory();

  const variables = useDebounce(params, 300);

  const { data: tickets, isLoading, refetch } = useTickets({
    variables: { ...variables, facilityId },
    refetchInterval: 1000 * 60 * 2, // 2 min
    refetchIntervalInBackground: true,
  });

  const {
    data: activeTicketTypesData,
    isLoading: isActiveTicketTypesLoading,
  } = useActiveTicketTypes({
    variables: { facilityId, simple: true },
  });

  const hasTicketUpdateRight = useCheckFacilityRights(RIGHT_KEYS.ticketUpdate, facilityId);

  const hasPersonalAccountModule = useHasFacilityModule(facilityId, MODULES_KEYS.personalAccounts);
  const hasPersonalAccountChargeRight = useCommonRights(RIGHT_KEYS.personalAccountsCharge);

  const activeTicketTypesItems = useMemo(() => (activeTicketTypesData || []).map(({ id, name }) => ({
    id,
    title: name,
  })), [activeTicketTypesData]);

  const {
    ticketsData,
    ticketsMeta,
  } = (tickets || {});

  const {
    sorting,
    tabValue,
    search,
    status,
    passType,
    dateFrom,
    dateTo,
    personalAccount,
  } = params;

  const handleParamChange = useCallback((paramName, newValue) => {
    if (params[paramName] === newValue) {
      return;
    }

    setParams((prevState) => ({
      ...prevState,
      page: 1,
      [paramName]: newValue,
    }));
  }, [params, setParams]);

  const handleDateRangeChange = useCallback((from, to) => {
    setParams((prevState) => ({
      ...prevState,
      page: 1,
      dateFrom: toNativeTimeFormat(from),
      dateTo: toNativeTimeFormat(to),
    }));
  }, [setParams]);

  function sortingSettingsChange({ headerNameId }) {
    const nextSortStatus = getNextSortStatus(sorting[headerNameId]);
    if (nextSortStatus === null) {
      handleParamChange('sorting', {});
      return;
    }

    handleParamChange('sorting', {
      [headerNameId]: getNextSortStatus(sorting[headerNameId]),
    });
  }

  const handleChanges = useCallback((event) => {
    const propName = event.target.name;
    const { value } = event.target;

    handleParamChange(propName, value);
  }, [handleParamChange]);

  const onPageChange = useCallback((_, pageNumber) => {
    handleParamChange('page', pageNumber);
  }, [handleParamChange]);

  return (
    <Grid container spacing={2}>
      {showControls && (
        <FiltersContainer>
          <Grid item xs={12}>
            <Grid container spacing={1}>
              <Grid item xs={12} sm={6} md={4} lg={3}>
                <CustomTextInput
                  style={{ margin: 0 }}
                  name="search"
                  label="Поиск"
                  value={search}
                  onChange={handleChanges}
                />
              </Grid>
              <Grid item xs={12} sm={6} md={4} lg={3}>
                <DateTimeRangePicker
                  label="Период действия"
                  inputFormat="DD.MM HH:mm"
                  clearable
                  rangesType="filters"
                  pastSearchFriendly
                  // smartMode
                  initStart={dateFrom ? moment(dateFrom) : null}
                  initEnd={dateTo ? moment(dateTo) : null}
                  onChange={({ start, end }) => {
                    handleDateRangeChange(start, end);
                  }}
                />
              </Grid>
              <Grid item xs={12} sm={6} md={4} lg={3}>
                <ItemSelectors
                  classNameForm={styles.statusSelectForm}
                  className={styles.statusSelect}
                  name="passType"
                  currentValue={passType}
                  items={activeTicketTypesItems}
                  defaultItem="Тип не выбран"
                  onChange={handleChanges}
                />
              </Grid>
              <Grid item xs={12} sm={6} md={4} lg={3}>
                <MultiItemSelectors
                  classNameForm={styles.statusSelectForm}
                  className={styles.statusSelect}
                  name="status"
                  currentValue={status}
                  items={STATUS_ITEMS}
                  unselectText="Статус не выбран"
                  onChange={handleChanges}
                />
              </Grid>
              {hasPersonalAccountModule && hasPersonalAccountChargeRight && (
                <Grid item xs={12} sm={6} md={4} lg={3}>
                  <PersonalAccountSelect
                    name="personalAccount"
                    currentValue={personalAccount}
                    onChange={handleChanges}
                    unselectText="Лицевой счет не выбран"
                  />
                </Grid>
              )}
              {hasTicketUpdateRight && (
                <Grid item xs={12}>
                  <Grid container spacing={1}>
                    <Grid item xs={12} sm={6} md={4} lg={3}>
                      <CustomBtn
                        className={styles.btn}
                        btnType="primaryGreen"
                        onClick={() => history.push(PATH_PAGES.addTicket.replace(':facilityId', facilityId))}
                      >
                        Добавить пропуск
                      </CustomBtn>
                    </Grid>
                  </Grid>
                </Grid>
              )}
            </Grid>
          </Grid>
        </FiltersContainer>
      )}
      {title && ((ticketsData || []).length !== 0 || !showTitleIfNotEmpty) && (
        <Grid item xs={12}>
          <Typography className={styles.title}>{title}</Typography>
        </Grid>
      )}
      <TicketsContent
        showIfNotEmpty={showContentIfEmpty}
        facilityId={facilityId}
        isLoading={isLoading}
        ticketsData={ticketsData}
        tikcetsMeta={ticketsMeta}
        showPagination={showPagination}
        onPageChange={onPageChange}
        sortingSettingsChange={sortingSettingsChange}
        sorting={sorting}
        onNeedUpdate={() => {
          refetch();
        }}
      />
    </Grid>
  );
}

export const TicketsWithParams = withParams({
  initParams: getInitParams(),
  prepareParam,
})(Tickets);

const StatusArrayEnumParam = createEnumDelimitedNumericArrayParam([
  PASSES_STATUSES.draft,
  // PASSES_STATUSES.awaitingPayment,
  PASSES_STATUSES.active,
  PASSES_STATUSES.canceled,
  PASSES_STATUSES.finished,
]);

const TicketsWithQueryParams = withQueryParams({
  queryParams: {
    dateTo: withDefault(StringParam, ''),
    dateFrom: withDefault(StringParam, ''),
    search: withDefault(StringParam, ''),
    // tabValue: withDefault(NumberParam, 0),
    status: withDefault(StatusArrayEnumParam, []),
    passType: withDefault(StringParam, 'unselect'),
    personalAccount: withDefault(DelimitedNumericArrayParam, []),
    page: PageParam,
    sorting: withDefault(ObjectParam, {}),
  },
  prepareParam,
})(Tickets);

export default TicketsWithQueryParams;
