import React, {
  useCallback, useEffect, useMemo, useState,
} from 'react';
import { connect } from 'react-redux';
import { CircularProgress } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import { Autocomplete } from '@material-ui/lab';
import throttle from 'lodash/throttle';

import CustomTextInput from '@parkly/shared/components/atoms/CustomTextInput';
import ChevronDownIcon from '@parkly/shared/components/atoms/icons/ChevronDownIcon';
import { formatPhoneNumber } from '@parkly/shared/helpers';

import { getCustomersSelectAction } from 'actions/customers';
import { ACCESS_TOKEN_TYPES, RIGHT_KEYS } from 'config/constants';
import { useCheckFacilityRights } from 'helpers/hooks';

import { useStyles } from './styles';

function getTokensData(tokens) {
  for (const { type, token } of (tokens || [])) {
    if (type === ACCESS_TOKEN_TYPES.plateNumber) {
      return token;
    }
  }

  return '';
}

function getCurrentCustomers({
  allCustomersSelectState,
  search,
  facilityId,
}) {
  const list = (allCustomersSelectState || {}).list || [];
  const preparedReqParam = {
    search: search ? search.trim() : undefined,
    facilityId,
  };
  const currentStrParam = JSON.stringify(preparedReqParam);
  const customers = list.find((item) => {
    const { strParam } = item || {};
    const isCurrent = strParam === currentStrParam;

    return isCurrent;
  });

  return {
    customers,
    reqParam: preparedReqParam,
    currentStrParam,
  };
}

function CustomerAsyncSelect({
  facilityId,
  onChange,
  error,
  allCustomersSelectState,
  getCustomersSelectReq,
  withoutNewCustomer = false,
  placeholder = 'Начните вводить для поиска',
  inputClassName,
  label = 'Клиент',
}) {
  const styles = useStyles();
  const [inputValue, setInputValue] = useState('');
  const [prevParams, setPrevParams] = useState('');

  const hasCustomerShowRight = useCheckFacilityRights(RIGHT_KEYS.customerShow, facilityId);

  const throttled = useMemo(() => throttle(
    getCustomersSelectReq,
    500,
    // { trailing: true },
  ), [getCustomersSelectReq]);

  useEffect(() => {
    if (!facilityId) {
      return;
    }

    if (inputValue === '') {
      return;
    }

    const {
      customers,
      reqParam,
      currentStrParam,
    } = getCurrentCustomers({
      allCustomersSelectState,
      search: inputValue,
      facilityId,
    });

    if (currentStrParam !== prevParams) {
      if (!customers) {
        throttled(reqParam);

        setPrevParams(currentStrParam);
      }
    }
  }, [allCustomersSelectState, facilityId, getCustomersSelectReq, inputValue, prevParams, throttled]);

  const {
    customers,
  } = getCurrentCustomers({
    allCustomersSelectState,
    search: inputValue,
    facilityId,
  });

  const { data: customersData } = customers || {};
  const { loading } = allCustomersSelectState || {};

  const customersItems = useMemo(() => {
    const result = [];

    if (!withoutNewCustomer) {
      result.push({ id: null, title: 'Добавить' });
    }

    (customersData || []).forEach(({
      id, name, phone, tokens,
    }) => {
      result.push({
        id,
        title: name,
        phone,
        fio: name,
        tokens,
      });
    });

    return result;
  }, [customersData, withoutNewCustomer]);

  const handleInputChange = useCallback((e, v, r) => {
    if (r !== 'input') {
      return;
    }

    setInputValue(v);
  }, []);

  return (
    <Autocomplete
      getOptionLabel={(option) => option.title}
      getOptionSelected={(option, value) => option.id === value.id}
      options={customersItems}
      onClose={() => {
        setInputValue('');
      }}
      onInputChange={handleInputChange}
      filterOptions={(filterOptions) => filterOptions}
      loading={loading}
      onChange={onChange}
      popupIcon={<ChevronDownIcon />}
      renderOption={({ title, phone, tokens }) => (
        <Typography className={styles.option}>
          {title}
          {' '}
          {hasCustomerShowRight && phone && (
            <span>{formatPhoneNumber(phone)}</span>
          )}
          {' '}
          <span>{getTokensData(tokens)}</span>
        </Typography>
      )}
      renderInput={(params) => (
        <CustomTextInput
          /* eslint-disable-next-line react/jsx-props-no-spreading */
          {...params}
          required
          error={error}
          label={label}
          margin="none"
          className={inputClassName}
          placeholder={placeholder}
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <>
                {loading ? <CircularProgress size={20} /> : null}
                {params.InputProps.endAdornment}
              </>
            ),
          }}
        />
      )}
    />
  );
}

function mapStateToProps(state) {
  const { customers } = state || {};
  const { allCustomersSelect } = customers || {};

  return {
    allCustomersSelectState: allCustomersSelect,
  };
}

const ConnectedCustomerAsyncSelect = connect(
  mapStateToProps,
  {
    getCustomersSelectReq: getCustomersSelectAction,
  },
)(CustomerAsyncSelect);

export default ConnectedCustomerAsyncSelect;
