import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
  formControl: {
    width: '100%',
    borderRadius: 6,
    backgroundColor: theme.palette.greyC.lightGrey,
  },
  select: {
    width: '100%',
    '&.MuiInput-underline:before': {
      display: 'none',
    },
    '& .MuiSelect-select:focus': {
      backgroundColor: 'rgb(0, 0, 0, 0)',
    },
    '& .MuiSelect-icon': {
      marginTop: 2,
      marginRight: 9,
    },
  },
  selectLabelClass: {
    marginTop: '14px!important',
  },
  label: {
    '&.MuiInputLabel-shrink': {
      transform: 'translate(12px, 6px) scale(0.85)',
    },
    color: theme.palette.greyC.darkGrey,
  },
  menuItem: {

  },
  statusItemStr: {
    // marginLeft: 5,
  },
  selectedStr: {
    paddingLeft: 12,
    paddingRight: 28,
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    '& span': {
      color: 'grey',
    },
  },
  unSelectStr: {
    paddingLeft: 12,
    paddingRight: 9,
    color: theme.palette.greyC.gray2,
  },

  secFormControl: {
    height: 40,
    backgroundColor: theme.palette.background.default,
    border: `1px solid ${theme.palette.greyC.buttonGrey}`,
  },
  secSelect: {
    '&.MuiInput-underline:after': {
      display: 'none',
    },
    '& .MuiInputBase-input': {
      padding: '8px 0 7px',
    },
  },

  selectMenu: {
    '& .MuiPopover-paper': {
      marginTop: 12,
    },
  },
}));

export default {
  useStyles,
};
