import React from 'react';
import { toast } from 'react-toastify';
import { CircularProgress, Grid } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import { useQueryClient } from '@tanstack/react-query';
import moment from 'moment-timezone';

import CustomBtn from '@parkly/shared/components/atoms/CustomBtn';
import CustomTextInput from '@parkly/shared/components/atoms/CustomTextInput';
import CustomModalContentWrapper from '@parkly/shared/components/templates/CustomModalContentWrapper';
import { toNativeTimeFormat, useFormFields } from '@parkly/shared/helpers';

import { useChangeSessionStatusMutation } from 'api/query/sessions';
import { SESSION_STATUSES } from 'config/constants';

import { useStyles } from './styles';

export default function SessionStopModalContent({
  onClose,
  onSuccess = () => {},

  id,
  facilityId,
}) {
  const styles = useStyles();
  const queryClient = useQueryClient();

  const [fields, errors, onChange] = useFormFields({
    initValues: {
      date: toNativeTimeFormat(moment()),
    },
  });

  const changeSessionStatusMutation = useChangeSessionStatusMutation({
    onSuccess({ success }) {
      if (success) {
        toast.success('Выполнено');
        queryClient.invalidateQueries({ queryKey: ['sessions'] });
        queryClient.invalidateQueries({ queryKey: ['customers'] });
        queryClient.invalidateQueries({ queryKey: ['passes'] });
        queryClient.invalidateQueries({ queryKey: ['tickets'] });

        onSuccess();
      }
    },
  });

  const { isLoading } = changeSessionStatusMutation || {};

  const onStopClick = () => {
    changeSessionStatusMutation.mutate({
      id,
      facilityId,
      status: SESSION_STATUSES.stopped,
      date: fields.date,
    });
  };

  return (
    <CustomModalContentWrapper
      title="Остановить сессию"
      onClose={onClose}
      paperClassname={styles.paper}
      bodyClassname={styles.modalBody}
    >
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Typography>
            Сессия будет остановлена с указанным ниже временем.
          </Typography>
          <Typography>
            Имеет смысл использовать, если пользователь покинул парковку.
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <CustomTextInput
            className={styles.input}
            label="Время"
            type="datetime-local"
            value={toNativeTimeFormat(fields.date)}
            name="date"
            onChange={onChange}
          />
        </Grid>
        <Grid item xs={12}>
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <CustomBtn
                className={styles.saveBtn}
                btnType="primaryRed"
                onClick={onClose}
              >
                Отменить
              </CustomBtn>
            </Grid>
            <Grid item xs={6}>
              <CustomBtn
                className={styles.saveBtn}
                disabled={isLoading}
                btnType="primaryGreen"
                onClick={onStopClick}
              >
                Остановить
                {isLoading && <CircularProgress size={20} className={styles.progress} />}
              </CustomBtn>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </CustomModalContentWrapper>
  );
}
